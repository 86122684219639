import { create } from 'zustand';

type State = {
    openModalConsultEditTableRules: boolean,
    selectAllConsultComercialTable: boolean,
    consultComercialProductItemSelectedList: any
}

type Actions = {
    setConsultComercialTableState: (name: string, data: any) => void,
    reset: () => void;
}

const initialState: State = {
    openModalConsultEditTableRules: false,
    selectAllConsultComercialTable: false,
    consultComercialProductItemSelectedList: [],
};

export const useConsultComercialTableStore = create<State & Actions>((set) => ({
    ...initialState,
    setConsultComercialTableState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    reset: () => {
        set(initialState);
    },
}));
