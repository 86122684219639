export const MODAL_UPLOAD_CONTENT = {
    PRODUTO: {
        title: 'Produtos de interesse',
        subtitle: 'Envie seus produtos de interesse através de um arquivo .csv',
        button: 'Enviar produtos',
        parcial_error_title: 'Não foi possível localizar alguns Produtos de Interesse',
        parcial_error_count: ' produtos são inválidos.',
        parcial_error_download_button: 'baixar produtos inválidos',
        parcial_error_description: 'Verifique o relatório de erros para identificar e corrigir os itens incorretos. Caso você queira prosseguir mesmo assim, sua cobertura poderá ser afetada.',
        full_error_title: 'Não localizamos os seus Produtos de Interesse.',
        full_error_description: 'Revise o relatório de erros, corrija-os e faça o upload da lista corrigida para continuar com sua Análise de Cobertura.',
        full_error_download_button: 'baixar produtos inválidos',
        exceed_limit_title: 'Limite de produtos excedido',
        exceed_limit_description: 'Mais de 15 mil produtos foram enviados para análise. Revise sua lista e reenvie-a com no máximo 15 mil produtos.',
    },
    LOJA: {
        title: 'Lojas específicas',
        subtitle: 'Envie suas lojas de interesse através de um arquivo .csv',
        button: 'Enviar lojas',
        parcial_error_title: 'Não foi possível localizar algumas Lojas de Interesse',
        parcial_error_count: ' lojas são inválidos.',
        parcial_error_download_button: 'baixar lojas inválidas',
        parcial_error_description: 'Verifique o relatório de erros para identificar e corrigir os itens incorretos. Caso você queira prosseguir mesmo assim, sua cobertura poderá ser afetada.',
        full_error_title: 'Os CNPJs enviados são inválidos.',
        full_error_description: 'Revise o relatório de erros, corrija-os e faça o upload da lista corrigida para continuar com sua Análise de Cobertura.',
        full_error_download_button: 'baixar lojas inválidas',
        exceed_limit_title: 'Limite de lojas excedido.',
        exceed_limit_description: 'Mais de 5 mil lojas foram enviadas para análise. Revise sua lista e reenvie-a com no máximo 5 mil lojas.',
    },
}