import { useState } from 'react';
import * as PainelGeralService from '../../../../../ISA/InfoPanel/InfoPanel/services';
import * as utils from '../utils';
import { useSetupInfoAnaliseDeCoberturaStore } from '../SetupInfoPanelAnaliseDeCobertura.store';
import { draftUploadProduct } from '../services';

type filterRequestObj = {
    key: string,
    datapoints: number,
}

type selectObj = {
    label: string,
    value: string,
    datapoints?: number,
}

export const useSetupCoberturaFilters = () => {
    const [timer, setTimer] = useState(null);

    const { filtrosAnaliseCobertura, draftCoverageSetupData, setSetupInfoAnaliseDeCoberturaState } = useSetupInfoAnaliseDeCoberturaStore();

    const setList = (name: string, list: filterRequestObj[]) => {
        const newList = list.map((item) => ({
            label: item.key,
            value: item.key,
            datapoints: item.datapoints,
        }));

        const cacheList = filtrosAnaliseCobertura[name].cache;
        const removeDuplicate = newList.filter(
            (i) => !filtrosAnaliseCobertura[name].value.includes(i.value),
        );
        const concatList = filtrosAnaliseCobertura[name].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        setSetupInfoAnaliseDeCoberturaState('filtrosAnaliseCobertura', {
            ...filtrosAnaliseCobertura,
            [name]: {
                ...filtrosAnaliseCobertura[name],
                list: concatList,
            },
        });
    };

    const getFilterSelectList = (name: string, params) => {
        PainelGeralService.getItensFiltros(params).then((data) => {
            if (data) {
                setList(name, data);
            }
        });
    };

    const handleSearchFilter = (name: string, inputValue: string) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getFilterSelectList(
                name,
                utils.getAnaliseCoberturaModel(
                    filtrosAnaliseCobertura,
                    inputValue,
                    name,
                    null
                ),
            );
        }, 500);

        setTimer(newTimer);
    };

    const handleAddFilterItem = (name: string, item: selectObj) => {
        const valueList = filtrosAnaliseCobertura[name].value;
        const newValue = valueList.concat(item.value);
        const filterItem = {
            ...filtrosAnaliseCobertura,
            [name]: {
                ...filtrosAnaliseCobertura[name],
                value: newValue,
                cache: [...filtrosAnaliseCobertura[name].cache, item],
            },
        }

        const draftItem = {
            ...draftCoverageSetupData,
            [name]: newValue,
        }

        setSetupInfoAnaliseDeCoberturaState('filtrosAnaliseCobertura', filterItem);
        setSetupInfoAnaliseDeCoberturaState('draftCoverageSetupData', draftItem);
    };

    const handleRemoveFilterItem = (name: string, item: selectObj) => {
        const removedCache = filtrosAnaliseCobertura[name].cache.filter(
            (i) => i.value !== item.value,
        );
        const newValue = filtrosAnaliseCobertura[name].value.filter(
            (i) => i !== item.value,
        );
        const draftItem = {
            ...draftCoverageSetupData,
            [name]: newValue,
        }

        setSetupInfoAnaliseDeCoberturaState('filtrosAnaliseCobertura', {
            ...filtrosAnaliseCobertura,
            [name]: {
                ...filtrosAnaliseCobertura[name],
                value: newValue,
                cache: removedCache,
            },
        });

        setSetupInfoAnaliseDeCoberturaState('draftCoverageSetupData', draftItem);
    };

    const handleSelectFilterItem = (name: string, item: string) => {
        if (filtrosAnaliseCobertura[name]?.value.includes(item.value)) {
            handleRemoveFilterItem(name, item);
        } else {
            handleAddFilterItem(name, item);
        }
    }

    const handleCleanFilter = (name: string) => {
        const obj = {
            ...filtrosAnaliseCobertura,
            [name]: {
                ...filtrosAnaliseCobertura[name],
                value: [],
                cache: [],
            },
        };
        setSetupInfoAnaliseDeCoberturaState('filtrosAnaliseCobertura', obj);
    };

    return {
        handlersCobertura: {
            getFilterSelectList,
            handleAddFilterItem,
            handleRemoveFilterItem,
            handleCleanFilter,
            handleSearchFilter,
            handleSelectFilterItem,
        },
    };
};
