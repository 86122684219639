import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

const URL = process.env.REACT_APP_IPA_PRICE_TABLES_MANAGER_URL

// ########## - TABELA COMERCIAL - ##########

export const getComercialTableList = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
        },
    }).then((res) => res),
    'table-edit-base'
);

export const putComercialTableList = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((res) => res),
);

export const postComercialTableList = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'POST',
        expectToken: true,
        data
    }).then((res) => res),
);

export const getConsultComercialTable = (id: number) => trackPromise(
    api({
        url: `${URL}/tabela-comercial/${id}`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
    'edit-comercial-table'
);

export const getComercialTableProducts = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
            idTabelaComercial: data.idTabelaComercial
        },
    }).then((res) => res),
    'table-edit-base'
);

export const saveComercialTableProductCost = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial/custos`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((res) => res),
    'table-edit-base'
);

export const saveComercialTableNewPrice = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial/preco`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((res) => res),
    'table-edit-base'
);

export const applyComercialPrices = (ids) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial/aplicar-precos`,
        method: 'PUT',
        expectToken: true,
        params: {
            idsRascunhoProduto: ids
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((res) => res),
    'table-edit-base'
);

export const selectAllComercialAplicarPrecos = (id: string) => trackPromise(
    api({
        url: `${URL}/tabela-comercial/aplicar-precos`,
        method: 'PUT',
        expectToken: true,
        params: {
            idTabelaComercial: id
        },
    }).then((res) => res),
);

export const saveOfertaPromo = (data: any) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial/criar-oferta`,
        method: 'PUT',
        expectToken: true,
        params: {
            idRascunhoProduto: data.idRascunhoProduto,
            precoOferta: data.precoOferta,
            inicioVigencia: data.inicioVigencia,
            terminoVigencia: data.terminoVigencia
        },
    }).then((res) => res),
    'criar-oferta'
);

export const cancelOfertaPromo = (id: number) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial/cancelar-oferta`,
        method: 'PUT',
        expectToken: true,
        params: {
            idRascunhoProduto: id,
        },
    }).then((res) => res),
    'criar-oferta'
);

export const removeComercialTable = (id: number) => trackPromise(
    api({
        url: `${URL}/tabela-comercial/excluir`,
        method: 'DELETE',
        expectToken: true,
        params: {
            idTabelaComercial: id
        },
    }).then((res) => res),
    'table-edit-base'
);
