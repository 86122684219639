import { useState } from 'react';
import * as service from '../services';
import { useSetupInfoAnaliseDeCoberturaStore } from '../SetupInfoPanelAnaliseDeCobertura.store';
import { UploadState } from '../@types';

export const useUploadCoverageFiles = () => {
    const [ uploadProgress, setUploadProgress ] = useState(0);
    const { setSetupInfoAnaliseDeCoberturaState} = useSetupInfoAnaliseDeCoberturaStore();

    const controller = new AbortController();

    const simulateUpload = () => {
            setUploadProgress(0);

            const duration = 1250;
            const interval = 100;
            const steps = duration / interval;
            let currentProgress = 0;

            const timer = setInterval(() => {
                currentProgress += (100 / steps);

                if (currentProgress >= 100) {
                    clearInterval(timer);
                    setUploadProgress(99);
                } else {
                    setUploadProgress(Math.min(currentProgress, 99));
                }
            }, interval);
        };

    const validateUploadProductFile = async (file: File) => {
        simulateUpload();
        setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.UPLOADING)

        const formData = new FormData();
        formData.append('file', file);

        try {
            const resp = await service.draftUploadProduct(formData, controller);
            const data = resp.data;
            const produtoData = {
                dataEnvio: data.dataEnvio,
                usuario: data.usuario,
                nomeArquivo: data.nomeArquivo,
            }
            setSetupInfoAnaliseDeCoberturaState('productUploadResponse', resp.data);
            setSetupInfoAnaliseDeCoberturaState('produtoTableData', produtoData);
            setSetupInfoAnaliseDeCoberturaState('isProductNewFile', true);
            if (resp.status === 200) {
                setSetupInfoAnaliseDeCoberturaState('openModalProductUpload', false);
            }

            if (resp.status === 207) { // Erro, GTIN parcialmente INVALIDO
                setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.PARCIAL);
            }

        } catch (error) { // Limite de GTIN EXCEDIDO
            setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.ERROR);
            setSetupInfoAnaliseDeCoberturaState('productUploadResponse', error.response.data);
        }
    };

    const validateUploadLojaFile = async (file: File) => {
            simulateUpload();
            setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.UPLOADING)

            const formData = new FormData();
            formData.append('file', file);

            try {
                const resp = await service.draftUploadStore(formData, controller);
                const data = resp.data;
                const lojaData = {
                    dataEnvio: data.dataEnvio,
                    usuario: data.usuario,
                    nomeArquivo: data.nomeArquivo,
                }
                setSetupInfoAnaliseDeCoberturaState('lojaUploadResponse', resp.data);
                setSetupInfoAnaliseDeCoberturaState('lojaTableData', lojaData);
                setSetupInfoAnaliseDeCoberturaState('isLojaNewFile', true);
                if (resp.status === 200) {
                    setSetupInfoAnaliseDeCoberturaState('openModalProductUpload', false);
                }

                if (resp.status === 207) { // Erro, LOJA parcialmente INVALIDO
                    setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.PARCIAL);
                }

            } catch (error) { // Limite de LOJA EXCEDIDO
                setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.ERROR);
                setSetupInfoAnaliseDeCoberturaState('lojaUploadResponse', error.response.data);
            }
        };

    const cancelUploadProductFile = () => {
        controller.abort();
    };

    return {
        uploadProgress,
        cancelUploadProductFile,
        validateUploadProductFile,
        validateUploadLojaFile,
    };
};
