/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MdUpload } from "react-icons/md";
import { ProductUploadProps } from '../../@types';
import styles from './ContentUpload.module.scss';
import Table from '../Table';

export const ContentUpload = ({
    error,
    tableData,
    isNewFile,
    uploadBoxTitle,
    uploadBoxSubtitle,
    onClickDownloadFile,
    onClickOpenModalUpload,
}: ProductUploadProps) => {
    const uploadBoxClass = error ? 'upload-box-error' : 'upload-box'
    const uploadBoxClassContent = error ? 'upload-content-error' : 'upload-content'
    const uploadBoxClassTitle = error ? 'upload-title-error' : 'upload-title'
    const uploadBoxClassSubtitle = error ? 'upload-description-error' : 'upload-description'

    return (
        <div className={styles[uploadBoxClass]}
            role="button"
            tabIndex={0}
            style={{ cursor: 'pointer' }}
        >
            <div
                className={styles[uploadBoxClassContent]}
                onClick={onClickOpenModalUpload}
            >
                <h3 className={styles[uploadBoxClassTitle]}>
                    {uploadBoxTitle}
                    <MdUpload className={styles.uploadIcon} />
                </h3>
                <p className={styles[uploadBoxClassSubtitle]}>
                    {uploadBoxSubtitle}
                </p>
            </div>
            {tableData[0]?.dataEnvio ? (
                <Table
                    data={tableData}
                    isNewFile={isNewFile}
                    onClickDownloadFile={onClickDownloadFile}
                />
            ) : null}
        </div>
    );
};
