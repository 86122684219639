/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
import { Button, Card, CardContent, Container, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockIcon from '@material-ui/icons/Lock';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, Notification } from 'rsuite';
import iconCheckGreen from '../../../assets/icons/icon_check_circle_green.svg';
import { AlertWithTimestamp } from '../../../components/AlertWithTimestamp';
import Exception from '../../../data/Exception';
import { SetupIPA, SetupInfopanel } from './Components';
import MpdvConfig from './MPDV';
import { getProductConfig, saveProductConfig } from './services';
const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            // marginTop: -20,
            height: 2,
            width: '100%',
            backgroundColor: '#378EF0',
        },
    },
})(Tabs);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#959595',
        fontWeight: theme.typography.fontWeightRegular,
        paddingTop: '35px',
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
        '& > span': {
            display: 'flex',
            flexDirection: 'row',
            '& > :first-child': {
                marginBottom: '0 !important',
                marginRight: '8px',
            },
        },
    },
    selected: {
        color: '#378EF0',
    },
}))(Tab);

const TabPanel = ({ children, value, index }) => (
    <div role="tabpanel" hidden={value !== index} id={`inf-tabpanel-${index}`} aria-labelledby={`inf-tab-${index}`} style={{ width: '100%', padding: '8px' }}>
        {value === index && (
            <Grid container spacing={2}>
                {children}
            </Grid>
        )}
    </div>
);

const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

const ProductsConfigPage = ({ usuario }) => {
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname;
    const queryClient = useQueryClient();

    const [tabValue, setTabValue] = useState();
    const [productConfigData, setProductConfigData] = useState('');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const permissions = {
        IPA: usuario?.servicos?.some(({ name }) => name === 'IPA'),
    };

    const getProductConfigData = async () => {
        const cliente = JSON.parse(localStorage.getItem('cliente'))?.codigo;
        const resp = await getProductConfig(cliente);

        if (resp?.data !== '') {
            setProductConfigData(resp?.data);
        } else {
            setProductConfigData('');
        }
    };

    const handleSaveProductConfig = async (type, data) => {
        const resp = await saveProductConfig(type, data);

        if (resp.status === 200) {
            Notification.open({
                className: 'product-config-success-notification',
                description: (
                    <>
                        <img src={iconCheckGreen} alt="" />
                        <p>Suas configurações foram salvas com sucesso.</p>
                    </>
                ),
            });
            getProductConfigData();
        } else {
            console.error(resp.status);
        }
    };

    const setStartTab = () => {
        const ipaPattern = /\/IPA\/.+/;
        const isaPattern = /\/ISA\/.+/;
        if ((usuario?.uploadPermitido || permissions.IPA) && ipaPattern.test(currentPath)) {
            setTabValue(3);
        } else if (isaPattern.test(currentPath)) {
            setTabValue(0);
        } else if (usuario?.servicoColeta) {
            setTabValue(1);
        } else if (usuario?.servicoPanel?.pacotePainel.name === 'FREE') {
            setTabValue(0);
        } else {
            setTabValue(null);
        }
    };

    const checkPermision = () => {
        if (usuario && !usuario.usuarioAdministrador) {
            const { message } = new Exception('Você não tem permissão para acessar a área de configurações');
            AlertWithTimestamp({ message });
            history.push('/login');
        }
    };

    useEffect(() => {
        getProductConfigData();
    }, []);

    useEffect(() => {
        setStartTab();
        checkPermision();
    }, [usuario]);


    const backPage = () => {
        if (currentPath.includes('administrador/produtos/IPA/dados-de-concorrencia')) {
            history.push('/administrador/pessoas');
        } else {
            history.goBack();
        }
    };

    return (
        <Container className="main-container upload-arquivos-page" maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button onClick={backPage} startIcon={<ArrowBackIcon />} className="back-btn">
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Card className="upload-arquivos-card" elevation={0}>
                        <CardContent className="upload-arquivos-card-content">
                            <Typography variant="h6">Produtos</Typography>
                            <StyledTabs value={tabValue} onChange={handleChange} aria-label="styled tabs example" TabIndicatorProps={{ children: <span /> }}>
                                <StyledTab
                                    label="ISA | Infopanel"
                                    disabled={false}
                                    icon={usuario?.servicoPanel?.pacotePainel.name !== 'FREE' ? <LockIcon fontSize="small" /> : null}
                                    {...a11yProps(0)}
                                    onClick={() => history.push('/administrador/produtos/ISA/analise-de-coberturas')}
                                />
                                <StyledTab
                                    label="ISA | Monitoramento em PDV"
                                    disabled={!usuario?.servicoColeta}
                                    icon={!usuario?.servicoColeta ? <LockIcon fontSize="small" /> : null}
                                    {...a11yProps(1)}
                                />
                                <StyledTab label="IRA | Analytics" icon={<LockIcon fontSize="small" />} disabled disableRipple {...a11yProps(2)} />
                                <StyledTab
                                    label="IPA | Software de Precificação"
                                    disabled={!permissions.IPA}
                                    icon={permissions.IPA ? <Icon icon="cog" /> : <LockIcon fontSize="small" />}
                                    {...a11yProps(3)}
                                />
                                <StyledTab label="Painéis Customizados" icon={<LockIcon fontSize="small" />} disabled disableRipple {...a11yProps(4)} />
                            </StyledTabs>
                        </CardContent>
                    </Card>
                </Grid>
                <TabPanel value={tabValue} index={0}>
                    <SetupInfopanel />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <MpdvConfig save={handleSaveProductConfig} data={productConfigData} />
                </TabPanel>
                <TabPanel className="setup-ipa-section" value={tabValue} index={3}>
                    <SetupIPA />
                </TabPanel>
            </Grid>
        </Container>
    );
};

export default ProductsConfigPage;
