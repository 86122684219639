import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Checkbox } from 'rsuite';
import styles from '../styles/ChannelSelection.module.scss';
import { useSetupInfoAnaliseDeCoberturaStore } from '../SetupInfoPanelAnaliseDeCobertura.store';

type ChannelType = 'canalFisico' | 'canalDigital'

export const ChannelSelection = ({ control }: { control: Control<FieldValues> }) => {
    const { draftCoverageSetupData, setSetupInfoAnaliseDeCoberturaState } = useSetupInfoAnaliseDeCoberturaStore();

    const handleChannelChange = (channel: ChannelType, checked: boolean, onChange) => {
        setSetupInfoAnaliseDeCoberturaState('draftCoverageSetupData', {
            ...draftCoverageSetupData,
            [channel]: checked,
        });
        onChange?.(checked);
    };

    return (
        <div className={styles['channel-container']}>
            <h3 className={styles.title}>Canal</h3>
            <p>Selecione um ou mais canais onde a cobertura será pesquisada</p>

            <div className={styles['checkbox-group']}>
                <div className={styles.items}>
                    <Controller
                        name="canalFisico"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Checkbox
                                checked={value}
                                value={value}
                                onChange={(_, checked) => handleChannelChange('canalFisico', checked, onChange)}
                            >
                                Físico
                            </Checkbox>
                        )}
                    />
                    <p className={styles['checkbox-group-paragraph']}>
                        Preços praticados na loja física, nota fiscal ou em Apps quando correspondem aos preços da loja
                    </p>
                </div>

                <div>
                    <Controller
                        name="canalDigital"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Checkbox
                                checked={value}
                                value={value}
                                onChange={(_, checked) => handleChannelChange('canalDigital', checked, onChange)}
                            >
                                Digital
                            </Checkbox>
                        )}
                    />

                    <p className={styles['checkbox-group-paragraph']}>
                        Preços praticados em sites/apps do varejo físico ou marketplace
                    </p>
                </div>
            </div>
        </div>
    );
};
