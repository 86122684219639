import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LoadingSpinerArea, SetupMenu, SetupMenuItem } from '../../../../../components';
import History from '../../../../../utils/History';
import { SetupInfoPanelAnaliseDeCobertura } from '../SetupInfoPanelAnaliseDeCobertura';
import styles from './SetupInfopanel.module.scss';

const PREFIX_URL = '/administrador/produtos/ISA';

const MENU_ITEMS = {
    'analise-de-coberturas': {
        to: 'analise-de-coberturas',
        label: 'Análise de Coberturas',
        component: SetupInfoPanelAnaliseDeCobertura,
    },
} as const;

const { to: DEFAULT_PATH } = MENU_ITEMS['analise-de-coberturas'];

type SetupISAProps = RouteComponentProps<Record<string, string>> & React.HtmlHTMLAttributes<HTMLDivElement>;

const SetupInfopanel = ({ className, match, ...props }: SetupISAProps) => {
    const params = match.params?.[0];

    const activePath = (params || DEFAULT_PATH) as keyof typeof MENU_ITEMS;

    const [selectedMenu, setSelectedMenu] = useState(activePath);

    useEffect(() => {
        setSelectedMenu(activePath);
    }, [activePath]);

    const MemoMenuItem = useMemo(() => {
        const menuItem = MENU_ITEMS[selectedMenu];
        return menuItem ? menuItem.component : MENU_ITEMS[DEFAULT_PATH].component;
    }, [selectedMenu]);

    if (!params) {
        History.push(`${PREFIX_URL}/${DEFAULT_PATH}`);
        return null;
    }

    return (
        <div className={classNames(styles['setup-infopanel'], className)} {...props}>
            <SetupMenu className={styles['setup-infopanel__setup-menu']}>
                {Object.entries(MENU_ITEMS).map(([key, { to, label }]) => (
                    <SetupMenuItem key={key} to={`${PREFIX_URL}/${to}`}>
                        {label}
                    </SetupMenuItem>
                ))}
            </SetupMenu>

            <div>
                <MemoMenuItem />
                <LoadingSpinerArea area="setup-ipa" height="100%" size="sm" />
            </div>
        </div>
    );
};

export default withRouter(SetupInfopanel);
