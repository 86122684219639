import classNames from 'classnames';
import React from 'react';
import styles from '../styles/SelectContainers.module.scss';

interface SelectContainersProps {
    children: React.ReactNode;
    title: string;
    className?: string;
    error?: boolean;
}

export const SelectContainers = ({ children, title, className, error, ...props }: SelectContainersProps) => {
    const isError = error ? styles.error : styles.container;
    return (
        <div className={classNames(className, isError)} {...props}>
            <h3 className={styles.title}>{title}</h3>
            {children}
        </div>
    );
};
