import React from 'react';
import { LoadingSpiner } from '../../../../../components';
import { ConfigFormSetupIsaAnaliseDeCobertura } from './components';
import styles from './SetupInfoPanelAnaliseDeCobertura.module.scss';

export const SetupInfoPanelAnaliseDeCobertura = () => {
    const isLoading = false;

    return (
        <div className={styles['setup-ipa__main-config-container']}>
            {isLoading && <LoadingSpiner size="md" />}
            <ConfigFormSetupIsaAnaliseDeCobertura />
        </div>
    );
};

