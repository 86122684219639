import React from 'react';
import styles from '../SetupInfoPanelAnaliseDeCobertura.module.scss';

export const AnaliseDeCoberturaInfo = () => {
    return <div className={styles['analise-de-cobertura-info']}>
        <p>
            Quanto mais específicas forem suas configurações, menor poderá ser a cobertura calculada.
            As configurações feitas nessa página não interferem nos dados disponibilizados no Infopanel.
        </p>
    </div>
};
