import React, { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FlexboxGrid, Input, InputGroup } from 'rsuite';
import { selectorDemandForecastFiltersArea, selectorDemandForecastSimulatorArea } from '../../../../../../../reducers/previsaoDemanda';
import { handleEnableButtonMain } from '../../../../../../../reducers/previsaoDemanda/filterArea';
import { removeInputList, setNewInputList, setNewValue } from '../../../../../../../reducers/previsaoDemanda/inputArea';
import { clearOutputData } from '../../../../../../../reducers/previsaoDemanda/outputArea';
import { useFilters } from '../../hooks/useFilters';

import { MdDeleteOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useSearchParams } from '../../../../../../../hooks/useSearchParams';
import { normalizeCompetitiveness, normalizeMargin } from '../../utils/normalizeValue';
import styles from './Inputs.module.scss';

export const SimulatorArea = () => {
    const [oldInputValue, setOldInputValue] = useState('');
    const simulatorArea = useSelector(selectorDemandForecastSimulatorArea);
    const filtersArea = useSelector(selectorDemandForecastFiltersArea);
    const { inputs, limitInput } = simulatorArea;
    const { getDemandForecastInputsValue } = useFilters();
    const params = useParams() as Record<'id', string>;
    const refs = useRef([]);

    const { getParam } = useSearchParams();
    const isCompareScenario = getParam('scenario') === 'compare';
    const isGerenciadorSource = getParam('source') === 'gerenciador';

    const dispatch = useDispatch();

    const calculateProductPrice = (newPrice: string, index: number) => {
        const value = newPrice.replace(',', '.');
        dispatch(setNewValue({ newprice: +value, index }));
    };

    const autoFocusInputValue =
        filtersArea.isCompleted && !params?.id && !isCompareScenario && inputs.length === 1 && !!inputs[0].newPrice && !isGerenciadorSource;

    useEffect(() => {
        // @ts-ignore
        if (!isGerenciadorSource) refs.current[inputs.length - 1]?.theInput?.focus();
    }, [inputs.length, isGerenciadorSource]);

    return (
        <main className={styles['simulation-container']}>
            <FlexboxGrid align="bottom" className={styles['page-title']}>
                <h3>Simulador</h3>
                <p>Veja o resultado da simulação para cada uma das lojas selecionadas</p>
            </FlexboxGrid>

            <div className={styles['inputs-wrapper']}>
                {inputs.map((input, index) => {
                    return (
                        <div key={index} className={styles['input-item']}>
                            <div className={styles['input-section']}>
                                {/* preco */}
                                <div className={styles['input-wrapper']}>
                                    <div>
                                        <InputGroup>
                                            <InputGroup.Addon>R$</InputGroup.Addon>
                                            <CurrencyInput
                                                // @ts-ignore
                                                ref={(ref: any) => (refs.current[index] = ref)}
                                                autoFocus={autoFocusInputValue}
                                                className={['rs-input', styles['input-container']].join(' ')}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                precision="2"
                                                allowEmpty
                                                value={input.newPrice}
                                                onChangeEvent={(e, value) => calculateProductPrice(value, index)}
                                                onBlur={(e) => {
                                                    getDemandForecastInputsValue(index);
                                                    dispatch(
                                                        handleEnableButtonMain({
                                                            buttonName: 'getOutput',
                                                            isDisabled: false,
                                                        }),
                                                    );

                                                    const value = inputs[index].newPrice?.toFixed(2);
                                                    if (oldInputValue !== value) {
                                                        dispatch(clearOutputData());
                                                    }
                                                }}
                                                onFocus={(e) => {
                                                    setOldInputValue(e.target.value.replace(',', '.'));
                                                    dispatch(
                                                        handleEnableButtonMain({
                                                            buttonName: 'getOutput',
                                                            isDisabled: true,
                                                        }),
                                                    );
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <p className={styles['simulation-input-label']}>Preço</p>
                                </div>

                                <div className={styles.divider} />

                                {/* competividade */}
                                <div className={styles['input-wrapper']}>
                                    <div>
                                        {input.competitiveness ? (
                                            <InputGroup>
                                                <CurrencyInput
                                                    className={['rs-input', styles['input-container']].join(' ')}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="1"
                                                    allowEmpty
                                                    value={normalizeCompetitiveness(input.competitiveness)}
                                                    disabled
                                                />
                                                <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                        ) : (
                                            <InputGroup>
                                                <Input className={[styles['input-container'], styles['input-empty']].join(' ')} disabled value="--" />
                                                <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                        )}
                                    </div>
                                    <p className={styles['simulation-input-label']}>Competitividade</p>
                                </div>

                                {/* margem */}
                                <div className={styles['input-wrapper']}>
                                    <div>
                                        {input.margin || input.margin === 0 ? (
                                            <InputGroup>
                                                <CurrencyInput
                                                    className={['rs-input', styles['input-container']].join(' ')}
                                                    name="margin"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="1"
                                                    allowEmpty
                                                    allowNegative
                                                    value={normalizeMargin(input.margin)}
                                                    disabled
                                                />
                                                <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                        ) : (
                                            <InputGroup>
                                                <Input className={[styles['input-container'], styles['input-empty']].join(' ')} disabled value="--" />
                                                <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                        )}
                                    </div>
                                    <p className={styles['simulation-input-label']}>Margem</p>
                                </div>
                            </div>

                            {inputs.length > 1 && (
                                <div className={styles['actions']}>
                                    <span>{(index + 1).toString().padStart(2, '0')}</span>
                                    {index > 0 && (
                                        <button onClick={() => dispatch(removeInputList({ index }))}>
                                            <MdDeleteOutline size={20} />
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {inputs.length < limitInput && (
                <Button
                    className={styles['add-scenario-button']}
                    appearance="link"
                    onClick={() => dispatch(setNewInputList())}
                    disabled={inputs.length >= limitInput}
                >
                    + Adicionar cenário para comparar
                </Button>
            )}
        </main>
    );
};
