export function getAnaliseCoberturaModel(props, query, name, identificadores) {
    return {
        query: query || '',
        field: name || null,
        login: JSON.parse(localStorage.getItem('usuario'))?.email,
        cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        addNotFound: true,
        canal: props.filtrosData?.canal || ['Canal físico', 'Canal digital'],
        identificadores: identificadores || null,
        preco: props.filtrosData?.preco?.name
            ? { name: props.filtrosData.preco.name }
            : { name: 'PRECO_PAGO' },
        gtin:
            name && name === 'produto'
                ? null
                : props.produto?.length
                ? props.produto
                : null,
        rede:
            name && name === 'rede'
                ? null
                : props.rede?.length
                ? props.rede
                : null,
        uf: name && name === 'uf' ? null : props?.uf?.length ? props.uf : null,
        cidade:
            name && name === 'cidade'
                ? null
                : props?.cidade?.length
                ? props.cidade
                : null,
        loja: name && name === 'loja' ? null : props?.loja?.length ? props.loja : null,
        tipoLoja: name && name === 'tipoLoja' ? null : props?.tipoLoja?.length ? props.tipoLoja : null,

    };
}
