import qs from 'qs';
import api from '../../../utils/API';
import { GetCategoriesRequest, GetOptimizationPriceRequest, SaveOptimizationPriceRequest, SearchScenarioParams } from './types/service';

export const getCategories = ({ params, nivel = 'categoryLevel4' }: GetCategoriesRequest) =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/filters`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente') ?? '{}')?.codigo || null,
            size: params?.size ? params.size : 20,
            field: nivel || null,
            ...(params?.query && { query: params.query }),
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const saveOptimization = (data: SaveOptimizationPriceRequest) =>
    api({
        url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/optimization`,
        method: 'POST',
        expectToken: true,
        data,
    }).then((resp) => resp.data);

export const updateOptimization = (id: string, data: SaveOptimizationPriceRequest) =>
    api({
        url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/optimization/${id}`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((resp) => resp.data);

export const getOptimizationById = (id: string) =>
    api({
        url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/optimization/${id}`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data);

export const getOptimizations = ({ params, data }: { params: SearchScenarioParams; data: GetOptimizationPriceRequest }) =>
    api({
        url:
            `${process.env.REACT_APP_DEMAND_FORECAST}/v2/optimization/search?page=${params?.page || 0}` +
            `&size=${params?.size || 10}&sort=${params?.sort || ''},${params?.order || 'asc'}`.trim(),
        method: 'POST',
        data,
        expectToken: true,
    }).then((resp) => resp.data);

export const deleteOptimization = (id: string) =>
    api({
        url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/optimization/${id}`,
        method: 'DELETE',
        expectToken: true,
    }).then((resp) => resp.data);
