import React from 'react'
import { SelectContainers } from '../SelectContainers'
import { Controller, FieldValues, FormState } from 'react-hook-form'
import styles from './CheckPickerController.module.scss'
import { useSetupInfoAnaliseDeCoberturaStore } from '../../SetupInfoPanelAnaliseDeCobertura.store'
import { useSetupCoberturaFilters } from '../../hooks/useSetupCoberturaFilters'
import { LoadingMenu } from '../../../../../../../components'
import { CheckPicker } from 'rsuite'

type CheckPickerControllerProps = {
    control: any,
    name: string,
    title?: string,
    error?: boolean,
    placeholder?: string
    required?: boolean,
    formState: FormState<FieldValues>,
}

export const CheckPickerController = ({
    control,
    name,
    title,
    placeholder,
    required,
    formState
}: CheckPickerControllerProps) => {
    const { filtrosAnaliseCobertura } = useSetupInfoAnaliseDeCoberturaStore();
    const { handlersCobertura } = useSetupCoberturaFilters();

    return (
        <SelectContainers title={title} error={!!formState?.errors[name]}>
            <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({
                    field: { value, onChange },
                    fieldState: { error },
                }) => (
                    <CheckPicker
                        className={styles[error ? 'check-picker-error' : 'check-picker']}
                        placeholder={placeholder}
                        value={value}
                        data={filtrosAnaliseCobertura[name].list}
                        onOpen={() => handlersCobertura.handleSearchFilter(name, '')}
                        onSelect={(_v, item) => handlersCobertura.handleSelectFilterItem(name, item)}
                        onSearch={(value) => handlersCobertura.handleSearchFilter(name, value)}
                        onClean={() => handlersCobertura.handleCleanFilter(name)}
                        onChange={onChange}
                        renderMenu={(menu) => (
                            <span>
                                <LoadingMenu area="panel-filter" />
                                {menu}
                            </span>
                        )}
                    />
                )}
            />
        </SelectContainers>
    )
}
