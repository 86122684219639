import React from 'react'
import { ProdutosInteresseContent } from '../ProdutosInteresseContent'
import { ContentUpload } from '../ContentUpload/ContentUpload'
import { ModalUploadCoverageSetup } from '../ModalUploadCoverageSetup'
import { useSetupInfoAnaliseDeCoberturaStore } from '../../SetupInfoPanelAnaliseDeCobertura.store'
import * as service from '../../services'
import styles from './InterestProducts.module.scss'
import { useUploadCoverageFiles } from '../../hooks/useUploadCoverageFiles'

const LINK_PRODUCT_MODEL_FILE = 'https://docs.google.com/spreadsheets/d/1zJxXtZ3jnR_xz9IkQpnUB8yuk0mYIi7ke5iKAZX7r3o/export?format=csv';

export const InterestProducts = () => {
    const {
        openModalProductUpload,
        productUploadResponse,
        productUploadError,
        isProductNewFile,
        produtoTableData,
        setSetupInfoAnaliseDeCoberturaState,
        setCoberturaProdutoTableData,
    } = useSetupInfoAnaliseDeCoberturaStore();

    const {
        uploadProgress,
        validateUploadProductFile,
    } = useUploadCoverageFiles();

    const handleClickCloseModalUpload = () => {
        setCoberturaProdutoTableData();
        setSetupInfoAnaliseDeCoberturaState('openModalProductUpload', false);
    };

    const handleDownloadProductUploadErrors = async () => {
        await service.downloadProductUploadErrors(productUploadResponse?.uploadId)
        setSetupInfoAnaliseDeCoberturaState('openModalProductUpload', false);
    };

    const handleDownloadUploadedProducts = async () => {
        if (isProductNewFile) {
            await service.downloadDraftUploadedProducts(productUploadResponse)
        } else {
            await service.downloadCurrentUploadedProducts(productUploadResponse)
        }
    };

    const handlClickOpenModalUpload = () => {
        setSetupInfoAnaliseDeCoberturaState('openModalProductUpload', true);
        setSetupInfoAnaliseDeCoberturaState('productUploadError', false);
    };

    return (
        <div className={styles.container}>
            <ProdutosInteresseContent href={LINK_PRODUCT_MODEL_FILE} />
            <ContentUpload
                error={productUploadError}
                tableData={[produtoTableData]}
                isNewFile={isProductNewFile}
                uploadBoxTitle="Enviar produtos via arquivo"
                uploadBoxSubtitle="Suba a sua lista de produtos selecionados para a análise através de um arquivo .csv"
                onClickDownloadFile={handleDownloadUploadedProducts}
                onClickOpenModalUpload={handlClickOpenModalUpload}
            />
            <ModalUploadCoverageSetup
                type='PRODUTO'
                show={openModalProductUpload}
                linkModelFile={LINK_PRODUCT_MODEL_FILE}
                invalidItems={productUploadResponse?.invalidos}
                uploadProgress={uploadProgress}
                onFileSelect={validateUploadProductFile}
                onHide={() => setSetupInfoAnaliseDeCoberturaState('openModalProductUpload', false)}
                onClickCloseAndReset={handleClickCloseModalUpload}
                onClickDownloadErrors={handleDownloadProductUploadErrors}
            />
        </div>
    )
}
