import { useMutation } from '@tanstack/react-query';
import { useSetupInfoAnaliseDeCoberturaStore } from '../SetupInfoPanelAnaliseDeCobertura.store';
import * as service from '../services';
import { NotificationMessage } from '../../../../../../components';

export const useSaveCoverage = () => {
    const {
        draftCoverageSetupData,
        productUploadResponse,
        lojaUploadResponse,
    } = useSetupInfoAnaliseDeCoberturaStore();

    const successAction = () => {
        NotificationMessage(
            'success',
            5000,
            'Alterações foram salvas com sucesso',
        );
    }

    const saveCoverageMutation = useMutation({
        mutationFn: (payload) => service.saveCoverage(payload),
        onSuccess: successAction,
    });

    const handleSaveCoverageMutation = () => {
        const { uf, cidade, tipoLoja, rede, canalDigital, canalFisico} = draftCoverageSetupData;

        const saveData = {
            uf,
            cidade,
            tipoLoja,
            rede,
            canalFisico,
            canalDigital,
            idUploadGtin: productUploadResponse.uploadId || null,
            idUploadLoja: lojaUploadResponse.uploadId || null,
        };

        saveCoverageMutation.mutate(saveData);
    };

    return {
        handleSaveCoverageMutation,
    };
};
