import React from 'react';
import { MdArrowBack, MdDelete, MdPlayArrow, MdTimeline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

export const Header = () => {
    const isEditing = false;

    return (
        <header className={styles.container}>
            <Link className={styles.back} to="/ipa/otimizacao-de-precos/lista">
                <MdArrowBack style={{ height: '24px', width: '24px' }} color="#505050" />
                <span>Voltar para lista de otimizações</span>
            </Link>

            <section className={styles.content}>
                <div className={styles.title}>
                    <p>Otimização de preços</p>
                    {isEditing ? <span className={styles.badge}>ativa até o dia 23/10/2023</span> : null}
                </div>
                {isEditing ? (
                    <div className={styles.buttons}>
                        <button className={styles['main-button']}>Gerenciar preços</button>
                        <button>
                            <MdPlayArrow style={{ height: '20px', width: '20px' }} />
                        </button>
                        <button>
                            <MdTimeline style={{ height: '20px', width: '20px' }} />
                        </button>
                        <button>
                            <MdDelete style={{ height: '20px', width: '20px' }} />
                        </button>
                    </div>
                ) : null}
            </section>
        </header>
    );
};
