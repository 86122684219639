import React from 'react';
import { BiLinkExternal } from "react-icons/bi";
import styles from './ProdutosInteresseContent.module.scss';

type ProdutosInteresseContentProps = {
    href?: string
}

export const ProdutosInteresseContent = ({ href }: ProdutosInteresseContentProps) => {
    return (
        <>
            <h2 className={styles.title}>
                Produtos de interesse
            </h2>

            <div className={styles.content}>
                <p className={styles.subtitle}>
                    Os produtos de interesse são os que serão levados em consideração no cálculo da sua Análise de Cobertura.
                    É possível enviar até 15 mil produtos.
                </p>
                <h4 >
                    Quais produtos incluir na minha lista?
                </h4>
                <p className={styles.description}>
                    Para garantir uma visão mais estratégica e precisa, siga estas recomendações ao selecionar os itens:
                </p>
                <p className={styles.list}>
                    <span>• Inclua apenas produtos com código GTIN e ativos;</span>
                    <br />
                    <span>• Para famílias, priorize os produtos mais relevantes.</span>
                </p>

                <p className={styles.description}>
                    Essa seleção evita distorções nos dados e proporciona uma análise mais clara e fiel à presença real dos produtos na cobertura.
                </p>
                <p className={styles.description}>
                    A Análise de Cobertura será realizada com base na lista enviada pelo usuário. Para alterações, basta subir uma nova lista.
                    <br />
                    Sempre que uma nova lista for enviada, o histórico de cobertura será reiniciado a partir da semana em que a análise for processada.
                </p>
                <p className={styles.description}>
                    <BiLinkExternal color="#0066cc" style={{ marginRight: '4px', marginBottom: '-2px' }} />

                    <a
                        href={href}
                        className={styles.link}
                        download
                    >
                        Baixe o modelo de arquivo .csv
                    </a>{' '}
                </p>
            </div>
        </>
    )
}
