import { create } from 'zustand';
import {
    CustoFabricacaoBodyType,
    CustosGeraisTabelaBodyType,
    CustosProdutoBodyType,
    EditBaseTableItemDataType,
    PriceAnalysisModalTableType,
    ProdutoDataType
} from '../EditBaseTable/types';
import { AtivarPromoDataTypes, ComercialTablePriceLimitAndRulesTypes } from './types';
import { comercialPrecoMedioProjetadoCalc } from '../../calculos';

type State = {
    openModalPriceAnalysisComercial: boolean,
    openModalActivateProductOffer: boolean,
    openModalProductCostComercialTable: boolean,
    openModalPriceLimit: boolean,
    priceAnalysisComercialTableData: any,
    productItemData: any,
    custosProdutoBody: any,
    comercialTableItem: any,
    comercialTablePriceLimitAndRules: ComercialTablePriceLimitAndRulesTypes,
    comercialNewPriceBody: any,
    comercialProductItemSelectedList: any,
    comercialPriceApplyList: any,
    selectAllTable: boolean,
    ativarPromoData: AtivarPromoDataTypes,
    hasOferta: boolean,
    contextMenuRowdata: any,
}

type Actions = {
    setManageComercialTableState: (name: string, data: any) => void,
    setOpenModalPriceAnalysisComercial: (open: boolean, data) => void,
    setOpenModalProductCostComercialTable: (open: boolean, data) => void,
    setOpenModalActivateProductOffer: (open: boolean, hasOferta: boolean, data: AtivarPromoDataTypes) => void,
    resetManageComercialTable: (name: string) => void,
    reset: () => void;
}

const initialState: State = {
    priceAnalysisComercialTableData: [],
    openModalPriceAnalysisComercial: false,
    openModalActivateProductOffer: false,
    openModalProductCostComercialTable: false,
    openModalPriceLimit: false,
    productItemData: {
        id: 0,
        criadoEm: '',
        atualizadoEm: '',
        versao: 0,
        produtoBase: {
            id: 0,
            criadoEm: '',
            atualizadoEm: '',
            versao: 0,
            produto: {
                id: null,
                criadoEm: '',
                atualizadoEm: '',
                versao: null,
                idCliente: null,
                data: '',
                codigoInternoProduto: '',
                tipoCodigoProduto: '',
                codigoEmbalagemProduto: '',
                codigoUnidadeProduto: '',
                codigoBarras: '',
                tipoCodigoBarras: '',
                descricaoProduto: '',
                status: '',
                unidadesPorEmbalagem: null,
                tamanhoEmbalagem: null,
                unidadeTamanhoEmbalagem: '',
                tipoEmbalagem: '',
                unidadeTipoEmbalagem: '',
                idFamiliaProduto: '',
                produtoMasterFamilia: false,
                marca: '',
                categoriaNivel1: '',
                categoriaNivel2: '',
                categoriaNivel3: '',
                categoriaNivel4: '',
                categoriaNivel5: '',
                categoriaNivel6: '',
                categoriaNivel7: '',
                ultimaAlteracao: '',
                codigoPrincipal: null,
                tierProduto: '',
            },
            parametros: {
                custoFabricacao: 0,
                precoMedio: {
                    valorAtual: 0,
                    ultimaDiferencaPercentual: 0,
                },
                novoPrecoTabela: 0,
            },
            tabela: {
                id: 0,
                criadoEm: '',
                atualizadoEm: '',
                versao: 0,
                idCliente: 0,
                uf: 'NACIONAL',
            },
            impostos: {
                ipi: 0,
                icms: 0,
                pis: 0,
                cofins: 0,
                outros: 0,
            },
            custosVenda: {
                freteMedio: 0,
                comissao: 0,
                trocasOuDevolucao: 0,
                gratificacao: 0,
                outros: 0,
            },
            despesaVenda: {
                comerciais: 0,
                administrativas: 0,
                outras: 0,
            },
        },
    },
    custosProdutoBody: {
        idRascunhoProdutoComercial: 0,
        custos: {
            freteMedio: 0,
            comissao: 0,
            trocasOuDevolucao: 0,
            gratificacao: 0,
            outros: 0
        },
        despesas: {
            comerciais: 0,
            administrativas: 0,
            outras: 0
        },
        custoFabricacao: 0,
    },
    comercialTableItem: {},
    comercialTablePriceLimitAndRules: {} as ComercialTablePriceLimitAndRulesTypes,
    comercialNewPriceBody: {
        idRascunhoProdutoComercial: 0,
        novoPrecoTabela: 0,
    },
    comercialProductItemSelectedList: [],
    comercialPriceApplyList: [],
    selectAllTable: false,
    ativarPromoData: {
        idRascunhoProduto: 0,
        precoOferta: 0,
        inicioVigencia: '',
        terminoVigencia: ''
    },
    hasOferta: false,
    contextMenuRowdata: {},
};

export const useManageComercialTableStore = create<State & Actions>((set) => ({
    ...initialState,
    setManageComercialTableState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    setOpenModalProductCostComercialTable: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalProductCostComercialTable: open,
            productItemData: data || initialState.productItemData,
            custosProdutoBody: {
                idRascunhoProdutoComercial: data?.id || 0,
                custos: {
                    freteMedio: data?.custosVenda.freteMedio,
                    comissao: data?.custosVenda.comissao,
                    trocasOuDevolucao: data?.custosVenda.trocasOuDevolucao,
                    gratificacao: data?.custosVenda.gratificacao,
                    outros: data?.custosVenda.outros
                },
                despesas: {
                    comerciais: data?.despesaVenda.comerciais,
                    administrativas: data?.despesaVenda.administrativas,
                    outras: data?.despesaVenda.outras
                },
                custoFabricacao: 0,
            },
        }));
    },
    setOpenModalPriceAnalysisComercial: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalPriceAnalysisComercial: open,
            priceAnalysisComercialTableData: [
                {
                    name: 'Tabela atual',
                    price: data ? data?.tabelaAtual?.tabelaAtual : 0,
                    diff: null
                },
                {
                    name: 'Preço médio atual',
                    price: data ? data?.produtoComercial?.precoMedioUfCanal : 0,
                    diff: null
                },
                {
                    name: 'Novo preço com desconto máximo',
                    price: 0,
                    diff: null
                },
                {
                    name: 'Novo preço de tabela',
                    price: data ? data?.parametros?.novoPrecoTabela?.valorNovo : 0,
                    diff: null
                },
                {
                    name: 'Preço médio projetado',
                    price: data ? comercialPrecoMedioProjetadoCalc(data) : 0,
                    diff: null
                },
            ]
        }));
    },
    setOpenModalActivateProductOffer: (open: boolean, hasOferta: boolean, data: AtivarPromoDataTypes) => {
        set(() => ({
            openModalActivateProductOffer: open,
            ativarPromoData: data,
            hasOferta,
        }));
    },
    resetManageComercialTable: (name: string) => {
        set(() => ({ [name]: initialState[name]}));
    },
    reset: () => {
        set(initialState);
    },
}));
