import { combineReducers } from '@reduxjs/toolkit';
import optimizationPrices, { OptimizationPricesProps } from './optimizationPrices';
import optimizationPricesList, { OptimizationPricesListProps } from './optimizationPricesList';

export type OptimizationPricesStateProps = {
    optimizationPrices: OptimizationPricesProps;
    optimizationPricesList: OptimizationPricesListProps;
};

const rootReducer = combineReducers({
    optimizationPrices: optimizationPrices,
    optimizationPricesList: optimizationPricesList,
});

export const optimizationPricesSelector = (state: Record<'optimizationPricesReducer', OptimizationPricesStateProps>) =>
    state.optimizationPricesReducer.optimizationPrices;

export const optimizationPricesListSelector = (state: Record<'optimizationPricesReducer', OptimizationPricesStateProps>) =>
    state.optimizationPricesReducer.optimizationPricesList;

export default rootReducer;
