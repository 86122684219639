import React from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MdCalendarToday, MdInfo } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxGroup, SelectPicker, Toggle } from 'rsuite';
import { InputBox } from '../../../../../../../components';
import { optimizationPricesSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { periodOptions } from '../../../../../../../reducers/OtimizacaoPrecos/optimizationPrices';
import { DatePickerCustom } from '../../../../components/DatePickerCustom/DatePickerCustom';
import { ShowErrors } from '../../../../components/ShowErrors';
import { useScheduleOptimizationPrices } from '../../../../hooks/useScheduleOptimizationPrices';
import { FormOptimizationPrices } from '../../../../types/schema.form';
import styles from './PeriodSection.module.scss';

const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'OK',
    today: 'Hoje',
    yesterday: 'Ontem',
    tomorrow: 'Amanhã',
    formattedMonthPattern: 'MMM yyyy',
    formattedDayPattern: 'DD/MM/YYYY',
    shortDateFormat: 'dd/MM/yyyy',
};

interface PeriodSectionProps {
    control: Control<FormOptimizationPrices>;
    watch: UseFormWatch<FormOptimizationPrices>;
    errors: FieldErrors<FormOptimizationPrices>;
    setValue: UseFormSetValue<FormOptimizationPrices>;
}
export const PeriodSection = ({ control, watch, errors, setValue }: PeriodSectionProps) => {
    const { scheduleOptimizationPrices } = useSelector(optimizationPricesSelector);
    const { handleSetOptions } = useScheduleOptimizationPrices();
    const { period } = scheduleOptimizationPrices;

    const periodType = watch('scheduleOptimizationPrices.period.value') || 'weekly';
    const isActiveEndDate = watch('scheduleOptimizationPrices.validity.end.active');

    const activePeriod = period.find((item) => item.value === periodType);

    return (
        <main className={styles.container}>
            <header className={styles.title}>
                <span>Defina quando recalcular os preços otimizados</span>
            </header>
            <section className={styles.content}>
                {/* periodo */}
                <section className={styles.box}>
                    <header className={styles.title}>
                        <span>período para recálculo</span>
                        <p>Defina um prazo para refazer o cálculo de otimização dos preços. Para um melhor resultado, indicamos otimizar mensalmente.</p>
                    </header>

                    <section className={styles.body}>
                        <section className={styles.inputs}>
                            <div className={styles['input-group']}>
                                <span>Período para nova otimização</span>

                                <Controller
                                    control={control}
                                    name="scheduleOptimizationPrices.period.value"
                                    render={({ field }) => {
                                        return (
                                            <SelectPicker
                                                data={period}
                                                value={field.value}
                                                onSelect={(value) => field.onChange(value)}
                                                searchable={false}
                                                style={{ width: 296 }}
                                                placeholder="Escolha uma opção"
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className={styles['input-group']}>
                                {activePeriod ? (
                                    <span className={styles.info}>
                                        {activePeriod.groupName}
                                        <MdInfo color="#959595" />
                                    </span>
                                ) : null}
                                <InputBox>
                                    <CheckboxGroup
                                        name="checkbox-group"
                                        inline
                                        className={styles.weekdays}
                                        value={activePeriod?.options?.filter((item) => item.active).map((item) => item.value) || []}
                                        onChange={(selectedValues) => {
                                            handleSetOptions(selectedValues);
                                            setValue('scheduleOptimizationPrices.period.options', [...selectedValues]);
                                        }}
                                    >
                                        {periodOptions[periodType]?.map((periodItem) => {
                                            return (
                                                <Checkbox key={periodItem.value} value={periodItem.value}>
                                                    {periodItem.label}
                                                </Checkbox>
                                            );
                                        })}
                                    </CheckboxGroup>
                                    <ShowErrors message={errors.scheduleOptimizationPrices?.period?.options?.message} />
                                </InputBox>
                            </div>
                        </section>
                    </section>
                </section>

                {/* vigencia */}
                <section className={styles.box}>
                    <header className={styles.title}>
                        <span>vigência</span>
                        <p>Escolha quando iniciar a aplicação dos preços otimizados. Você também pode definir uma data final para a otimização. </p>
                    </header>

                    <section className={styles.body}>
                        <section className={styles.inputs}>
                            <div className={styles['input-group']} style={{ minWidth: 184 }}>
                                <span>Início vigência</span>

                                <Controller
                                    control={control}
                                    name="scheduleOptimizationPrices.validity.start.startDate"
                                    render={({ field, fieldState: { error } }) => (
                                        <InputBox>
                                            <DatePickerCustom
                                                format="DD/MM/YYYY"
                                                placement="topStart"
                                                value={field.value}
                                                onChange={(value) => field.onChange(value)}
                                                oneTap
                                                ranges={[]}
                                                icon={<MdCalendarToday color="#959595" />}
                                                locale={locale}
                                            />
                                            <ShowErrors message={error?.message} />
                                        </InputBox>
                                    )}
                                />
                            </div>

                            <div className={styles['input-group']} style={{ minWidth: 184 }}>
                                <div className={styles.info}>
                                    <Controller
                                        control={control}
                                        name="scheduleOptimizationPrices.validity.end.active"
                                        render={({ field }) => <Toggle size="sm" checked={field.value} onChange={(value) => field.onChange(value)} />}
                                    />

                                    <span>Final da vigência</span>
                                    <MdInfo color="#959595" />
                                </div>

                                <Controller
                                    control={control}
                                    name="scheduleOptimizationPrices.validity.end.endDate"
                                    render={({ field, fieldState: { error } }) => (
                                        <InputBox>
                                            <DatePickerCustom
                                                style={{ color: '#959595' }}
                                                disabled={!isActiveEndDate}
                                                format="DD/MM/YYYY"
                                                placeholder="Final da vigência"
                                                placement="topStart"
                                                value={field.value || undefined}
                                                onChange={(value) => field.onChange(value)}
                                                oneTap
                                                icon={<MdCalendarToday color="#959595" />}
                                                ranges={[]}
                                                locale={locale}
                                            />
                                            <ShowErrors message={error?.message} />
                                        </InputBox>
                                    )}
                                />
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </main>
    );
};
