/* eslint-disable react/jsx-indent */
import React, { useRef, useState } from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { Button, Modal, Progress } from 'rsuite';
import { FileTypes, ModalUploadProductProps, UploadState } from '../../@types';
import { useSetupInfoAnaliseDeCoberturaStore } from '../../SetupInfoPanelAnaliseDeCobertura.store';
import { ButtonPrimary } from '../../../../../../../components';
import attachment from '../../../../../../../assets/icons/attachment.svg';
import icon_error_info from '../../../../../../../assets/icons/error.svg';
import icon_alert_circle from '../../../../../../../assets/icons/icon_alert_circle.svg';
import { MODAL_UPLOAD_CONTENT } from './ModalUploadCoverageSetup.data';
import styles from './ModalUploadCoverageSetup.module.scss';

export const ModalUploadCoverageSetup = ({
    show,
    linkModelFile,
    type,
    invalidItems,
    uploadProgress,
    onFileSelect,
    onHide,
    onClickCloseAndReset,
    onClickDownloadErrors,
}: ModalUploadProductProps) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const {
        uploadState,
        setSetupInfoAnaliseDeCoberturaState
    } = useSetupInfoAnaliseDeCoberturaStore();

    const resetStates = () => {
        setSelectedFile(null);
        setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.IDLE);
    };

    const handleHideClose = (type: 'close' | 'hide') => {
        resetStates();
        if (type === 'close') {
            onClickCloseAndReset(); // fechar o modal, resetar o estado e tabela
        } else {
            onHide(); // fechar a modal, resetar o estado e manter os dados da tabela
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            if (file.type !== 'text/csv') {
                setSetupInfoAnaliseDeCoberturaState('uploadState', UploadState.ERROR);
            }
            onFileSelect(file);
            setSelectedFile(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleCancelUpload = () => {
        resetStates();
    };

    const downloadInfoComponent = (
        <div className={styles.fileName}>
            <div className={styles.fileNameContent}>
                <img src={attachment} alt="Attachment" className={styles.fileIcon} />
                <span className={styles.fileNameText} title={selectedFile?.name}>
                    {selectedFile?.name}
                </span>
            </div>
            <span className={styles.fileSize}>
                {Math.round(selectedFile?.size / 1024)}KB
            </span>
        </div>
    )

    const renderContent = () => {
        if (uploadState === UploadState.PARCIAL) {
            return (
                <div className={styles.successState}>
                    {selectedFile && downloadInfoComponent}
                    <div className={styles.checkmark}>
                        <img src={icon_error_info} alt="Erro" />
                        <h4>{MODAL_UPLOAD_CONTENT[type]?.parcial_error_title}</h4>
                        <p>
                            <span>{invalidItems}</span>
                            {MODAL_UPLOAD_CONTENT[type]?.parcial_error_count}
                        </p>
                        <p style={{ marginTop: '12px' }}>
                            {MODAL_UPLOAD_CONTENT[type]?.parcial_error_description}
                        </p>
                    </div>
                </div>
            );
        }
        if (uploadState === UploadState.ERROR) {
            return (
                <div className={styles.successState}>
                    {selectedFile && downloadInfoComponent}
                    <div className={styles.checkmark}>
                        <img src={icon_error_info} alt="Erro" />
                        <h4>{MODAL_UPLOAD_CONTENT[type]?.full_error_title}</h4>
                        <p style={{ marginTop: '12px' }}>
                            {MODAL_UPLOAD_CONTENT[type]?.full_error_description}
                        </p>
                    </div>
                </div>
            );
        }
        if (uploadState === UploadState.EXCEED) {
            return (
                <div className={styles.successState}>
                    {selectedFile && downloadInfoComponent}
                    <div className={styles.checkmark}>
                        <img src={icon_alert_circle} alt="alerta-limite" />
                        <h4>{MODAL_UPLOAD_CONTENT[type]?.exceed_limit_title}</h4>
                        <p style={{ marginTop: '12px' }}>
                            {MODAL_UPLOAD_CONTENT[type]?.exceed_limit_description}
                        </p>
                    </div>
                </div>
            );
        }
        return (
            <>
                {selectedFile && uploadState === UploadState.UPLOADING && (
                    <div className={styles.successState}>
                        {downloadInfoComponent}
                        <Progress.Circle
                            percent={uploadProgress}
                            strokeWidth={4}
                            width={70}
                            height={70}
                        />
                        <div>
                            <span className={styles.uploadMessage}>
                                O arquivo está sendo enviado e o tempo de espera dependerá do tamanho do arquivo. <strong>Por favor, aguarde...</strong>
                            </span>
                            <span>
                                <Button
                                    appearance="link"
                                    className={styles.cancelButton}
                                    onClick={handleCancelUpload}
                                >
                                    Cancelar envio
                                </Button>
                            </span>
                        </div>
                    </div>
                )}

                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    accept={FileTypes.CSV}
                    style={{ display: 'none' }}
                />

                {uploadState !== UploadState.UPLOADING && (
                    <Button
                        appearance="primary"
                        className={styles.uploadButton}
                        onClick={handleUploadClick}
                    >
                        {MODAL_UPLOAD_CONTENT[type]?.button}
                    </Button>
                )}
            </>
        );
    };

    return (
        <Modal
            className={styles.modal}
            show={show}
            onHide={() => handleHideClose('close')}
        >
            <Modal.Header>
                <div className={styles.title}>
                    <h3 className={styles.modalTitle}>{MODAL_UPLOAD_CONTENT[type]?.title}</h3>
                </div>
            </Modal.Header>
            <div className={styles.content}>
                <p className={styles.description}>
                    {MODAL_UPLOAD_CONTENT[type]?.subtitle}
                </p>
                {renderContent()}
            </div>
            {uploadState === UploadState.IDLE && (
                <div className={styles.infoBox}>
                    <p className={styles.infoBoxText}>
                        Baixe o modelo, preencha e reenvie o mesmo modelo, sem alterar as informações que já estão no documento.
                    </p>
                    <a
                        href={linkModelFile}
                        className={styles.infoBoxLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Baixar modelo <FaExternalLinkAlt />
                    </a>
                </div>
            )}

            {uploadState === UploadState.PARCIAL ? (
                <div className={styles.buttonsContainer}>
                    <Button
                        type="button"
                        className={styles.closeErrorButton}
                        onClick={() => handleHideClose('hide')}
                    >
                        PROSSEGUIR MESMO ASSIM
                    </Button>
                    <ButtonPrimary
                        className={styles.downloadButton}
                        type="button"
                        onClick={onClickDownloadErrors}
                    >
                        {MODAL_UPLOAD_CONTENT[type]?.parcial_error_download_button}
                        <IoMdDownload />
                    </ButtonPrimary>
                </div>
            ) : null}
            {uploadState === UploadState.ERROR ? (
                <div className={styles.buttonsContainer}>
                    <ButtonPrimary
                        className={styles.downloadButton}
                        type="button"
                        onClick={onClickDownloadErrors}
                    >
                        {MODAL_UPLOAD_CONTENT[type]?.parcial_error_download_button}
                        <IoMdDownload />
                    </ButtonPrimary>
                </div>
            ) : null}
            {uploadState === UploadState.EXCEED ? (
                <div className={styles.buttonsContainer}>
                    <Button
                        className={styles.closeErrorButton}
                        type="button"
                        appearance="subtle"
                        onClick={resetStates}
                    >
                        Enviar novamente
                    </Button>
                </div>
            ) : null}
            {uploadState === UploadState.UPLOADING || uploadState === UploadState.IDLE ? (
                <Modal.Footer className={styles.footer}>
                    <Button
                        onClick={() => handleHideClose('close')}
                        appearance="subtle"
                        className={styles.closeButton}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            ) : null}
        </Modal>
    );
};