import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useSetupInfoAnaliseDeCoberturaStore } from '../SetupInfoPanelAnaliseDeCobertura.store';

export const useGetCoverage = () => {
    const {
        setCoverageData
    } = useSetupInfoAnaliseDeCoberturaStore();

    const getCoverageData = async () => {
        const resp = await service.getCoverage();
        return resp;
    };

    const {
        data, refetch,
    } = useQuery(
        {
            queryKey: ['get-setup-coverage'],
            queryFn: getCoverageData,
            retry: false,
            onSuccess: (data) => {
                setCoverageData(data.data);
            },
        },
    );

    const setupData = useMemo(() => data, [data]);

    return {
        coverageData: setupData,
    };
};
