import React from 'react';
import styles from '../styles/Table.module.scss';
import { formatDateToPtBr } from '../../../../../../utils/date';
import { Button, Tooltip, Whisper } from 'rsuite';

interface DataTable {
    dataEnvio: string
    usuario: string
    nomeArquivo: string
}

interface Data {
    data: DataTable[],
    isNewFile: boolean,
    onClickDownloadFile: () => void,
}

const Table = ({ data, isNewFile, onClickDownloadFile }: Data) => {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Data</th>
                    <th>Usuário</th>
                    <th>Nome do arquivo</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr
                        key={index}
                        className={index === 0 ? styles.normal : styles.opacity}
                    >
                        <td>{item.dataEnvio ? formatDateToPtBr(item.dataEnvio) : null}</td>
                        <td>{item.usuario}</td>
                        <td className={styles.fileName}>
                            <Button
                                appearance="link"
                                className={styles['btn-filename']}
                                onClick={onClickDownloadFile}
                            >
                                {item.nomeArquivo}
                            </Button>
                            {isNewFile ? (
                                <Whisper
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            A verificação da sua lista foi realizada.
                                            Salve suas preferências para que a Análise de Cobertura entre em vigor.
                                        </Tooltip>
                                    }
                                >
                                    <div className={styles.currentFileLabel}></div>
                                </Whisper>
                            ) : null}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
