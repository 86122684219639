import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

export const saveCoverage = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/save`,
            method: 'POST',
            data,
            expectToken: true,
        }).then((resp) => resp),
        'setup-coverage'
    );

export const draftUploadProduct = (file, controller) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/draft/gtin/upload`,
            method: 'POST',
            expectToken: true,
            headers: {
                contentType: 'multipart/form-data',
            },
            data: file,
            signal: controller.signal,
        }).then((resp) => resp),
        'setup-coverage'
    );

export const getCoverage = () =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp),
        'setup-coverage'
    );

export const downloadProductUploadErrors = (id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/draft/gtin/download/errors`,
        method: 'GET',
        expectToken: true,
        params: {
            uploadId: id,
        },
        responseType: 'arraybuffer',
    }).then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = 'produtos_erros.csv';
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'setup-coverage',
);

export const downloadDraftUploadedProducts = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/draft/gtin/download`,
        method: 'GET',
        expectToken: true,
        params: {
            uploadId: data.uploadId,
        },
        responseType: 'arraybuffer',
    }).then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `${data.nomeArquivo}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'setup-coverage',
);

export const downloadCurrentUploadedProducts = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/download/gtin`,
        method: 'GET',
        expectToken: true,
        responseType: 'arraybuffer',
    }).then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `${data.nomeArquivo}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'setup-coverage',
);

// LOJAS

export const draftUploadStore = (file, controller) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/draft/loja/upload`,
            method: 'POST',
            expectToken: true,
            headers: {
                contentType: 'multipart/form-data',
            },
            data: file,
            signal: controller.signal,
        }).then((resp) => resp),
        'setup-coverage'
    );

export const downloadStoreUploadErrors = (id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/draft/loja/download/errors`,
        method: 'GET',
        expectToken: true,
        params: {
            uploadId: id,
        },
        responseType: 'arraybuffer',
    }).then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = 'produtos_erros.csv';
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'setup-coverage',
);

export const downloadDraftUploadedStore = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/draft/loja/download`,
        method: 'GET',
        expectToken: true,
        params: {
            uploadId: data.uploadId,
        },
        responseType: 'arraybuffer',
    }).then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `${data.nomeArquivo}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'setup-coverage',
);

export const downloadCurrentUploadedStore = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_SETUP_URL}/coverage/download/loja`,
        method: 'GET',
        expectToken: true,
        responseType: 'arraybuffer',
    }).then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `${data.nomeArquivo}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'setup-coverage',
);