import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Days = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const weeklyOptions = [
    {
        active: false,
        label: 'Dom',
        value: 'sunday',
    },
    {
        active: false,
        label: 'Seg',
        value: 'monday',
    },
    {
        active: false,
        label: 'Ter',
        value: 'tuesday',
    },
    {
        active: false,
        label: 'Qua',
        value: 'wednesday',
    },
    {
        active: false,
        label: 'Qui',
        value: 'thursday',
    },
    {
        active: false,
        label: 'Sex',
        value: 'friday',
    },
    {
        active: false,
        label: 'Sáb',
        value: 'saturday',
    },
];

export const periodOptions = {
    weekly: weeklyOptions,
    monthly: weeklyOptions,
};

type SelectedFilters = {
    categoriesId: string[];
    categoryLevel: CategoryLevel;
    storesId: string[];
};

type SearchFilters = {
    categories: string;
    stores: string;
};

export type FilterDataProps = Record<string, unknown> & {
    label: string;
    value: string;
    description: string;
};

export type StoresProps = FilterDataProps & { store_id: string };
export type CategoriesProps = FilterDataProps;

export type PeriodOptions = {
    active: boolean;
    label: string;
    value: string;
};

export interface CategoryLevel {
    field: string;
    name: string;
    label: string;
    value: string;
}

export type CoordinatesRestriction = { name: 'margin' | 'competitiveness'; active: boolean; min: number; max: number };

type List = {
    stores: StoresProps[];
    categoryLevel: CategoryLevel[];
    categories: CategoriesProps[];
};

export type Period = {
    active: boolean;
    available: boolean;
    label: string;
    value: string;
    groupName: string;
    options: PeriodOptions[];
};

export type PeriodValues = 'weekly' | 'monthly';
export type OptimizationPricesProps = {
    filters: {
        list: List;
        selectedFilters: SelectedFilters;
        search: SearchFilters;
    };
    scheduleOptimizationPrices: {
        period: Period[];
    };
};

const initialState: OptimizationPricesProps = {
    filters: {
        list: {
            categoryLevel: [],
            categories: [],
            stores: [],
        },
        selectedFilters: {
            categoriesId: [],
            categoryLevel: { field: '', name: '', label: '', value: '' },
            storesId: [],
        },
        search: {
            categories: '',
            stores: '',
        },
    },
    scheduleOptimizationPrices: {
        period: [
            {
                active: true,
                available: true,
                label: 'Semanal',
                groupName: 'Dia da semana',
                value: 'weekly',
                options: periodOptions.weekly,
            },
            {
                active: false,
                available: false,
                label: 'Mensal',
                groupName: 'Mes',
                value: 'monthly',
                options: periodOptions.monthly,
            },
        ].filter((item) => item.available),
    },
};

const slice = createSlice({
    name: 'optimization-prices',
    initialState,
    reducers: {
        setStores(state, { payload }: PayloadAction<{ stores: StoresProps[] }>) {
            state.filters.list.stores = payload.stores;
        },
        setCategories(state, { payload }: PayloadAction<{ categories: FilterDataProps[] }>) {
            state.filters.list.categories = payload.categories;
        },
        setCategoryLevel(state, { payload }: PayloadAction<{ category: CategoryLevel[] }>) {
            state.filters.list.categoryLevel = payload.category;
        },
        setSearch(state, { payload }: PayloadAction<{ type: 'stores' | 'categories'; value: string }>) {
            state.filters.search[payload.type] = payload.value;
        },
        setSelectedFilters(state, { payload }: PayloadAction<{ type: 'storesId' | 'categoriesId'; value: string[] }>) {
            state.filters.selectedFilters[payload.type] = payload.value;
        },
        setSelectedFilterCategoryLevel(state, { payload }: PayloadAction<{ categoryLevel: CategoryLevel }>) {
            state.filters.selectedFilters.categoryLevel = payload.categoryLevel;
        },
        setPeriod(state, { payload }: PayloadAction<{ period: PeriodValues }>) {
            state.scheduleOptimizationPrices.period = state.scheduleOptimizationPrices.period.map((item) => {
                if (item.value === payload.period) {
                    return {
                        ...item,
                        active: true,
                    };
                }
                return { ...item, active: false };
            });
        },
        setOption(state, { payload }: PayloadAction<{ optionValue: string }>) {
            const activePeriodIndex = state.scheduleOptimizationPrices.period.findIndex((item) => item.active);

            if (activePeriodIndex < 0) return;

            const option = {
                ...state.scheduleOptimizationPrices.period[activePeriodIndex],
                options: state.scheduleOptimizationPrices.period[activePeriodIndex].options.map((item) => {
                    if (item.value === payload.optionValue) {
                        return {
                            ...item,
                            active: !item.active,
                        };
                    }
                    return { ...item };
                }),
            };

            state.scheduleOptimizationPrices.period[activePeriodIndex] = option;
        },
        setOptions(state, { payload }: PayloadAction<{ options: string[] }>) {
            const activePeriodIndex = state.scheduleOptimizationPrices.period.findIndex((item) => item.active);
            if (activePeriodIndex < 0) return;

            const options = state.scheduleOptimizationPrices.period[activePeriodIndex].options;
            const optionsMapped = options.map((item) => {
                return {
                    ...item,
                    active: payload.options.includes(item.value),
                };
            });
            state.scheduleOptimizationPrices.period[activePeriodIndex].options = optionsMapped;
        },
        resetOptimizationPrices: () => initialState,
    },
});

export const {
    setPeriod,
    setOption,
    setStores,
    resetOptimizationPrices,
    setSearch,
    setSelectedFilters,
    setCategories,
    setCategoryLevel,
    setSelectedFilterCategoryLevel,
    setOptions,
} = slice.actions;

export default slice.reducer;
