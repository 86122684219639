export enum UploadState {
    IDLE = 'idle',
    UPLOADING = 'uploading',
    SUCCESS = 'success',
    ERROR = 'error',
    PARCIAL = 'parcial',
    EXCEED = 'exceed',
}

export enum FileTypes {
    CSV = '.csv'
}

export type ModalUploadProductProps = {
    show: boolean;
    linkModelFile: string;
    type?: 'PRODUTO' | 'LOJA' | undefined;
    invalidItems: number;
    uploadProgress: number;
    onFileSelect: (file: File) => void;
    onHide: () => void;
    onClickCloseAndReset: () => void;
    onClickDownloadErrors: () => void;
};

export type ModalUploadStoreProps = {
    show: boolean;
    linkModelFile: string;
    onHide: () => void;
    onClickClose: () => void;
    onClickDownloadErrors: () => void;
};

export interface ActionButtonsProps {
    onCancel?: () => void;
    onSave?: () => void;
    onAnalyze?: () => void;
}


export interface AlertModalProps {
    show: boolean;
    onHide: () => void;
}

export interface ChannelSelectionProps {
    onChange?: (channels: 'canalFisico' | 'canalDigital') => void;
}

export enum CoverageType {
    TODO_TERRITORIO = 'TODO_TERRITORIO',
    UF = 'UF',
    CIDADE = 'CIDADE',
}

export enum StoreType {
    TODAS = 'TODAS',
    TIPO_LOJA = 'TIPO_LOJA',
    REDE = 'REDE',
    LOJA = 'LOJA',
}


export enum Action {
    PRODUCT_UPLOAD = "product_upload",
    STORE_UPLOAD = "store_upload"
}

export interface ProductUploadProps {
    error: boolean,
    tableData: any,
    isNewFile: boolean,
    uploadBoxTitle: string,
    uploadBoxSubtitle: string,
    onClickDownloadFile: () => void,
    onClickOpenModalUpload: () => void,
}

export type CoverageUploadResponseType = {
    uploadId: string,
    invalidos: number,
    nomeArquivo: string,
    usuario: string,
    dataEnvio: string,
};

export type CoverageFormValues = {
    cidade: string[],
    uf: string[],
    loja: string[],
    tipoLoja: string[],
    rede: string[],
    canalFisico: boolean,
    canalDigital: boolean,
    localInteresse: CoverageType.TODO_TERRITORIO | CoverageType.UF | CoverageType.CIDADE,
    lojaInteresse: StoreType.TODAS | StoreType.TIPO_LOJA | StoreType.REDE | StoreType.LOJA,
}

export type CoverageSetupResponseData = {
    comFiltroLoja: boolean;
    nomeArquivoGtins: string;
    usuarioEnvioGtins: string;
    dataEnvioGtins: string;
    nomeArquivoLojas: string;
    usuarioEnvioLojas: string;
    dataEnvioLojas: string;
} & CoverageFormValues
