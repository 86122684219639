import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { LoadingSpinerLocal } from '../../../../../../../../components';
import { selectorDemandForecastOutputArea, selectorDemandForecastSimulatorArea } from '../../../../../../../../reducers/previsaoDemanda';
import { Input } from '../../../../../../../../reducers/previsaoDemanda/inputArea';
import { RevenueDemandChartData } from '../../../../../../../../reducers/previsaoDemanda/outputArea';
import { formatNumbersToPtBr } from '../../../../../../../../utils/FormatNumbers';

import { ReloadStyleProps } from '../ResultScenarios';
import styles from './ElasticSimpleChart.module.scss';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

export const HighchartsOptions: Highcharts.Options = {
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        exitFullscreen: 'Sair da tela cheia',
    },
};

Highcharts.setOptions(HighchartsOptions);

const yAxis = (name: 'demanda' | 'receita' | 'preco', item: RevenueDemandChartData) => {
    const y = {
        demanda: item.demand,
        receita: parseInt(item.revenue.toFixed(0)),
        preco: item.price,
    };

    return y[name];
};

const setDataChart = (name: 'demanda' | 'receita', data: RevenueDemandChartData[], inputs: Input[]) => {
    const foundPrices = data.filter((item) => item.inputPrice === true);

    const basePrice = inputs[0].value;

    const currentRevenue = foundPrices?.find((item) => item.price === basePrice)?.demand;
    const currentDemand = foundPrices?.find((item) => item.price === basePrice)?.revenue;

    const newData = data.map((item) => ({
        name,
        x: item.price,
        y: yAxis(name, item),
        demand: item.demand,
        revenue: item.revenue,
        currentPrice: basePrice,
        currentRevenue,
        currentDemand,
        marker: {
            enabled: item.inputPrice,
            states: {
                hover: {
                    enabled: item.inputPrice,
                },
            },
        },
    }));

    return newData;
};

const setPlotLines = (data: RevenueDemandChartData[]) => {
    const filterData = data.filter((item) => item.inputPrice === true).map((item) => item.price);
    const minor = Math.min(...filterData);
    const major = Math.max(...filterData);

    return { minor, major };
};

export const ElasticSimpleChart = ({ styleProps }: { styleProps: ReloadStyleProps }) => {
    const elasticSimpleChartRef = useRef(null);

    const { revenueDemandChartData, profitRevenueChartData } = useSelector(selectorDemandForecastOutputArea);
    const { inputs } = useSelector(selectorDemandForecastSimulatorArea);

    if (!revenueDemandChartData.length && !profitRevenueChartData.length) {
        return (
            <div className={classNames(styles.container, styles['container-loading'])}>
                <LoadingSpinerLocal position="relative" size="md" height="100%" loading />
            </div>
        );
    }

    const formatMoney = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

    const defaultChartOption = {
        chart: {
            type: 'spline',
        },
        title: {
            text: '',
        },
        exporting: {
            enabled: false,
        },
        tooltip: {
            useHTML: true,
            pointFormatter(this: any): string {
                const currentRevenue = Math.floor(this.currentRevenue);
                const currentDemand = this.currentDemand;

                const price = this.x;
                const revenueNewPrice = Math.floor(this.revenue);
                const demandNewPrice = Math.floor(this.demand);

                return `
                    <p>
                        Preço Atual: <b> ${formatNumbersToPtBr(this.currentPrice, 'currency', formatMoney)}</b>
                    </p>
                    <div>
                        <span style="color:#E663C9">
                            <b>●</b>
                        </span>
                        <span>
                            Receita: <b>
                                 ${currentDemand ? formatNumbersToPtBr(currentDemand, 'currency', formatMoney) : 0}
                            </b>
                        </span>
                    </div>
                    <div>
                        <span style="color:#0D66D0">
                            <b>■</b>
                        </span>
                        <span>
                            Demanda: <b>${currentRevenue ? formatNumbersToPtBr(currentRevenue, 'decimal', { maximumFractionDigits: 0 }) : 0} un</b>
                        </span>
                    </div>
                    <br/>
                    <p>
                        Novo preço: <b> ${formatNumbersToPtBr(price, 'currency', formatMoney)}</b>
                    </p>
                    <div>
                        <span style="color:#E663C9">
                            <b>●</b>
                        </span>
                        <span>
                            Receita: <b> ${revenueNewPrice ? formatNumbersToPtBr(revenueNewPrice, 'currency', formatMoney) : 0}</b>
                        </span>
                    </div>
                    <div>
                        <span style="color:#0D66D0">
                            <b>■</b>
                        </span>
                        <span>
                            Demanda: <b>${demandNewPrice ? formatNumbersToPtBr(demandNewPrice, 'decimal', { maximumFractionDigits: 0 }) : 0} un</b>
                        </span>
                    </div>
                `;
            },
            headerFormat: '',
        },
        legend: {
            symbolWidth: 50,
        },
        yAxis: [
            {
                title: {
                    text: 'RECEITA',
                },
                accessibility: {
                    description: 'RECEITA',
                },
                opposite: true,
                labels: {
                    formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
                        const price = this.axis.defaultLabelFormatter.call(this);
                        return `R$ ${price.replace(',', '.')}`;
                    },
                },
            },
            {
                title: {
                    text: 'IMPACTO DEMANDA',
                },
                accessibility: {
                    description: 'IMPACTO DEMANDA',
                },
            },
        ],

        xAxis: {
            plotBands: [
                {
                    from: setPlotLines(revenueDemandChartData).minor,
                    to: setPlotLines(revenueDemandChartData).major,
                    color: '#F5F9FE',
                },
            ],
            plotLines: [
                {
                    value: setPlotLines(revenueDemandChartData).minor,
                    color: '#BCBCBC',
                    width: 1,
                    zIndex: 1.5,
                    dashStyle: 'ShortDashDot',
                },
            ],
            title: {
                text: 'PREÇO',
            },
            accessibility: {
                description: 'PREÇO',
            },
            labels: {
                formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
                    let price = this.axis.defaultLabelFormatter.call(this);
                    if (price.includes('.')) {
                        price = price.replace('.', '');
                    }

                    if (price.includes(',')) {
                        price = price.replace(',', '.');
                    }
                    return `R$ ${Number(price).toFixed(2).replace('.', ',')}`;
                },
            },
        },
        plotOptions: {},
        series: [
            {
                name: 'Demanda',
                data: setDataChart('demanda', revenueDemandChartData, inputs),
                color: '#0D66D0',
                marker: {
                    symbol: 'square',
                },
                yAxis: 1,
            },
            {
                name: 'Receita',
                data: setDataChart('receita', revenueDemandChartData, inputs),
                color: '#E663C9',
            },
            {
                name: 'Preço Atual',
                color: '#BCBCBC',
                marker: {
                    fillColor: '#FFFFFF',
                    lineWidth: 0,
                    width: 0.5,
                },
            },
        ],
    };

    return (
        <div className={styles.container} style={{ ...styleProps, height: '100%' }}>
            <HighchartsReact ref={elasticSimpleChartRef} highcharts={Highcharts} options={defaultChartOption} />
        </div>
    );
};
