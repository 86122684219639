/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
import { format } from 'date-fns';
import React, { useRef } from 'react';
import { MdCheckCircleOutline, MdHighlightOff, MdInfo, MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip, Whisper } from 'rsuite';
import { WhisperTrigger } from 'rsuite/lib/Whisper';
import { InfoTable } from '../../../../../../../components';
import { RowData, toggleModal } from '../../../../../../../reducers/OtimizacaoPrecos/optimizationPricesList';
import { useGridTableActions } from '../../../../hooks/useGridTableActions';
import styles from './GridTable.module.scss';
import { CellWrapper } from './components/CellWrapper/CellWrapper';
import { DropdownEventKey, TableOptions } from './components/TableOptions';

const { Column, HeaderCell, Cell } = InfoTable;

const DATA_KEY = 'optimizationgrid';

const lengthMenu = [
    {
        value: 5,
        label: 5,
    },
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

export const PeriodType = {
    weekly: 'Semanal',
    monthly: 'Mensal',
};

export const GridTable = () => {
    const refs = useRef<{ [id: string]: WhisperTrigger }>({});
    const history = useHistory();

    const dispatch = useDispatch();
    const { tableHandlers, tableState } = useGridTableActions();

    const paginationData = {
        total: tableState.pagination.totalElements,
        activePage: tableState.pagination.number + 1,
        displayLength: tableState.pagination.size,
        lengthMenu,
        onChangePage: tableHandlers.handleChangePage,
        onChangeLength: tableHandlers.handleChangeSize,
    };
    function handleSelectMenu(eventKey: string, id: string) {
        const triggerRef = refs.current[id];

        switch (eventKey) {
            case DropdownEventKey['delete-optimization']:
                dispatch(toggleModal({ name: 'delete', id: id.toString() }));
                break;
            case DropdownEventKey['edit-optimization']:
                history.push(`/ipa/otimizacao-de-precos/${id}`);
                break;
            default:
                break;
        }

        // @ts-ignore
        triggerRef?.hide();
    }

    return (
        <main className={styles.container}>
            <InfoTable
                data={tableState.data}
                dataKey={DATA_KEY}
                className={styles.table}
                loading={false}
                sortColumn={tableState.sortColumn || 'edited_at'}
                sortType={tableState.orderBy || 'asc'}
                minHeight={50}
                headerHeight={34}
                rowHeight={64}
                onSortColumn={tableHandlers.handleSetSortColumn}
                pagination={paginationData}
                rowClassName={(rowData: unknown) => ((rowData as RowData)?.status === 'ativa' ? '' : styles.expiredRow)}
            >
                {/* @ts-ignore */}
                <Column width={40}>
                    <HeaderCell />
                    <Cell>
                        {(rowData: RowData) => {
                            return (
                                <CellWrapper>
                                    {rowData.status === 'ativa' ? (
                                        <MdCheckCircleOutline color="#378EF0" style={{ height: 16, width: 16 }} />
                                    ) : (
                                        <MdHighlightOff color="#959595" style={{ height: 16, width: 16 }} />
                                    )}
                                </CellWrapper>
                            );
                        }}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable flexGrow={1} minWidth={254}>
                    <HeaderCell>Categoria</HeaderCell>
                    <Cell dataKey="optimization.categories">
                        {(rowData: RowData) => {
                            return (
                                <CellWrapper className={styles['main-content']}>
                                    <Whisper trigger="hover" placement="top" speaker={<Tooltip>{rowData.categories.split(',').join(', ')}</Tooltip>}>
                                        <div className={styles.highlight}>{rowData.categories.split(',').join(', ')}</div>
                                    </Whisper>
                                    {rowData?.totalProducts > 0 ? (
                                        <div className={styles['sub-content-category']}>
                                            {rowData.totalProducts} {rowData.totalProducts > 1 ? 'produtos' : 'produto'}
                                        </div>
                                    ) : null}
                                </CellWrapper>
                            );
                        }}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable flexGrow={1} minWidth={124}>
                    <HeaderCell>Loja</HeaderCell>
                    <Cell dataKey="optimization.stores.store_id">
                        {(rowData: RowData) => (
                            <CellWrapper className={styles['main-content']}>
                                <Whisper
                                    trigger="hover"
                                    placement="top"
                                    speaker={<Tooltip>{rowData.stores?.map((store) => store.storeId).join(', ')}</Tooltip>}
                                >
                                    <span className={styles.highlight}>{rowData.stores?.map((store) => store.storeId).join(', ')}</span>
                                </Whisper>
                                <Whisper
                                    trigger="hover"
                                    placement="top"
                                    speaker={<Tooltip>{rowData.stores?.map((store) => store.storeName).join(', ')}</Tooltip>}
                                >
                                    <span className={styles['sub-content-store']}>{rowData.stores?.map((store) => store.storeName).join(', ')}</span>
                                </Whisper>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={104}>
                    <HeaderCell>Frequência</HeaderCell>
                    <Cell dataKey="optimization.recalculate.period">
                        {(rowData: RowData) => (
                            <CellWrapper className={styles['main-content']}>
                                <span>{PeriodType[rowData.period as keyof typeof PeriodType]}</span>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={208}>
                    <HeaderCell>Responsável</HeaderCell>
                    <Cell dataKey="optimization.responsible">
                        {(rowData: RowData) => (
                            <CellWrapper className={styles['main-content']}>
                                <span>{rowData.responsible}</span>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={132}>
                    <HeaderCell>Última otimização</HeaderCell>
                    <Cell dataKey="edited_at">
                        {(rowData: RowData) => (
                            <CellWrapper className={styles['main-content']}>
                                {rowData.lastOptimization ? <span>{format(new Date(rowData.lastOptimization), 'dd/MM/yyyy')}</span> : '---'}
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={224}>
                    <HeaderCell>
                        <div className={styles['column-header']}>
                            <span>Projeção de resultado</span>
                            <MdInfo className={styles['info-icon']} />
                        </div>
                    </HeaderCell>
                    <Cell dataKey="resultsProjection">
                        {(rowData: RowData) => (
                            <CellWrapper className={styles['main-content']}>
                                {rowData.resultsProjection ? (
                                    <>
                                        <Whisper
                                            trigger="hover"
                                            placement="top"
                                            speaker={
                                                <Tooltip>
                                                    <div>
                                                        <p>{`Lucro projetado: ${rowData.resultsProjection?.valor} - ${rowData.resultsProjection?.percentual}`}</p>
                                                        <p>{`Faturamento projetado: ${rowData.resultsProjection?.referencia}`}</p>
                                                        <p>{`Margem projetada: ${rowData.resultsProjection?.referencia_percentual}`}</p>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={styles['result-projection']}>
                                                <span className={styles.highlight}>{rowData.resultsProjection?.valor}</span>
                                                <span className={styles['badge-green']}>{rowData.resultsProjection?.percentual}</span>
                                            </div>
                                        </Whisper>

                                        <div className={styles['result-projection']}>
                                            <span className={styles['sub-content-projection']}>{rowData.resultsProjection?.referencia}</span>
                                            <span className={styles['badge-gray']}>{`M. ${rowData.resultsProjection?.referencia_percentual}`}</span>
                                        </div>
                                    </>
                                ) : (
                                    '---'
                                )}
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={96} align="center">
                    <HeaderCell />
                    <Cell>
                        {(rowData: RowData) => {
                            return (
                                <Whisper
                                    placement="auto"
                                    trigger="click"
                                    triggerRef={(whisperRef: WhisperTrigger) => (refs.current[rowData.optimizationId] = whisperRef)}
                                    speaker={<TableOptions onSelect={(eventKey) => handleSelectMenu(eventKey, rowData.optimizationId)} />}
                                >
                                    <Button>
                                        <MdMoreVert />
                                    </Button>
                                </Whisper>
                            );
                        }}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column width={8} align="center">
                    <HeaderCell />
                    <Cell />
                </Column>
            </InfoTable>
        </main>
    );
};
