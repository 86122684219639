import React from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import { CoverageFormValues } from '../../@types';
import { ActionButtons } from '../ActionButtons';
import { AnaliseDeCoberturaHeader } from '../AnaliseDeCoberturaHeader';
import { AnaliseDeCoberturaInfo } from '../AnaliseDeCoberturaInfo';
import { ChannelSelection } from '../ChannelSelection';
import { InterestPoints } from '../InterestPoints';
import { SetupInfoPanelCallout } from '../SetupInfoPanelCallout';
import { useGetCoverage } from '../../hooks/useGetCoverage';
import { useSetupInfoAnaliseDeCoberturaStore } from '../../SetupInfoPanelAnaliseDeCobertura.store';
import { LoadingSpinerArea } from '../../../../../../../components';
import { InterestProducts } from '../InterestProducts';
import { useSaveCoverage } from '../../hooks/useSaveCoverage';
import icon_alert_circle from '../../../../../../../assets/icons/icon_alert_circle.svg';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import styles from './ConfigFormSetupIsaAnaliseDeCobertura.module.scss'
import { useHistory } from 'react-router-dom';

export const ConfigFormSetupIsaAnaliseDeCobertura = () => {
    const history = useHistory();

    const {
        draftCoverageSetupData,
        coverageSetupData,
        openModalCoverageAlert,
        setSetupInfoAnaliseDeCoberturaState
    } = useSetupInfoAnaliseDeCoberturaStore();

    useGetCoverage();

    const { handleSubmit, control, formState } = useForm<CoverageFormValues>({
        mode: 'onSubmit',
        values: {
            localInteresse: draftCoverageSetupData.localInteresse,
            lojaInteresse: draftCoverageSetupData.lojaInteresse,
            cidade: draftCoverageSetupData.cidade,
            uf: draftCoverageSetupData.uf,
            loja: draftCoverageSetupData.loja,
            rede: draftCoverageSetupData.rede,
            tipoLoja: draftCoverageSetupData.tipoLoja,
            canalFisico: draftCoverageSetupData.canalFisico,
            canalDigital: draftCoverageSetupData.canalDigital,
        },
    });

    const { handleSaveCoverageMutation } = useSaveCoverage();

    const onSubmit = () => {
        if (typeof draftCoverageSetupData.dataEnvioGtins !== 'string') { // verifica upload de produto
            setSetupInfoAnaliseDeCoberturaState('productUploadError', true);
            return
        }

        if ( // verifica upload de loja
            draftCoverageSetupData.lojaInteresse === 'LOJA'
            && typeof draftCoverageSetupData.dataEnvioLojas !== 'string'
        ) {
            setSetupInfoAnaliseDeCoberturaState('lojaUploadError', true);
            return
        }

        handleSaveCoverageMutation();
    };

    const handleCancel = () => {
        setSetupInfoAnaliseDeCoberturaState('draftCoverageSetupData', coverageSetupData);
    };

    const handleAnalyze = () => {
        if (!_.isEqual(draftCoverageSetupData, coverageSetupData)) {
            setSetupInfoAnaliseDeCoberturaState('openModalCoverageAlert', true);
        } else {
            history.push('/isa/analise-cobertura');
        }

    };

    const handleSavePreferences = () => {
        setSetupInfoAnaliseDeCoberturaState('openModalCoverageAlert', false);
        onSubmit();
    };

    const handleExitAndNoSave = () => {
        setSetupInfoAnaliseDeCoberturaState('openModalCoverageAlert', false);
        setSetupInfoAnaliseDeCoberturaState('draftCoverageSetupData', coverageSetupData);
        history.push('/isa/analise-cobertura');
    };

    return (
        <div>
            <LoadingSpinerArea area="setup-coverage" />

            <form onSubmit={handleSubmit(onSubmit)}>
                <AnaliseDeCoberturaHeader />
                <AnaliseDeCoberturaInfo />
                <InterestProducts />
                <InterestPoints control={control} formState={formState} />
                <ChannelSelection control={control} />
                <SetupInfoPanelCallout />
                <ActionButtons
                    onCancel={handleCancel}
                    onAnalyze={handleAnalyze}
                />
            </form>
            <ModalConfirm
                show={openModalCoverageAlert}
                className={styles['modal-alert']}
                modalWidth="480px"
                titleFontSize="16px"
                textAlign="center"
                footerAlign="center"
                icon={icon_alert_circle}
                title="Suas preferências ainda não foram salvas."
                message="Antes de seguir, salve suas preferências para garantir a configuração da análise."
                confirmButton="salvar preferências"
                cancelButton="sair sem salvar"
                closeButton={true}
                onConfirm={handleSavePreferences}
                onCancel={handleExitAndNoSave}
                onHide={() => setSetupInfoAnaliseDeCoberturaState('openModalCoverageAlert', false)}
            />
        </div>
    );
};
