import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'rsuite';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { optimizationPricesSelector } from '../../../../reducers/OtimizacaoPrecos';
import { resetOptimizationPrices, setOptions, setPeriod, setSelectedFilters } from '../../../../reducers/OtimizacaoPrecos/optimizationPrices';
import { getOptimizationById, saveOptimization, updateOptimization } from '../services';
import { FormOptimizationPrices, schema } from '../types/schema.form';
import { SaveOptimizationPriceRequest } from '../types/service';

export function useFormOptimization() {
    const history = useHistory();
    const { getParam } = useSearchParams();
    const params = useParams() as Record<'id', string>;
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { filters } = useSelector(optimizationPricesSelector);
    const { selectedFilters, list } = filters;
    const { stores } = list;

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm<FormOptimizationPrices>({
        defaultValues: {
            coordinates: {
                objective: 'profit',
                minVariationToChangePrice: 0.0,
            },
            scheduleOptimizationPrices: {
                period: {
                    value: 'weekly',
                    options: [],
                },
                validity: {
                    start: {
                        active: true,
                        startDate: new Date(),
                    },
                },
            },
        },
        resolver: zodResolver(schema),
        shouldFocusError: false,
        mode: 'onSubmit',
    });

    const onSubmit = async (formData: FormOptimizationPrices) => {
        const { coordinates, scheduleOptimizationPrices } = formData;
        const { validity, period } = scheduleOptimizationPrices;

        const data: SaveOptimizationPriceRequest = {
            category_level: selectedFilters.categoryLevel.field,
            categories: selectedFilters.categoriesId,
            stores: stores
                .filter((store) => selectedFilters.storesId.includes(store.store_id))
                .map((store) => ({ store_id: store.store_id, store_name: store.label })),
            objective: coordinates.objective,
            restrictions: {
                min_competitiveness: coordinates.competitiveness?.min ? coordinates.competitiveness?.min : null,
                max_competitiveness: coordinates.competitiveness?.max ? coordinates.competitiveness?.max : null,
                min_margin: coordinates.margin?.min ? coordinates.margin?.min : null,
                max_margin: coordinates.margin?.max ? coordinates.margin?.max : null,
                variation: coordinates.minVariationToChangePrice,
            },
            recalculate: {
                days_of_week: period.options,
                period: period.value,
            },
            validity: {
                start_date: validity.start.startDate,
                end_date: validity?.end?.active ? validity.end.endDate : null,
            },
        };

        let message = '';
        if (params.id) {
            await updateOptimization(params.id, data);
            message = 'Otimizacao atualizada com sucesso';
        } else {
            await saveOptimization(data);
            message = 'Otimização salva com sucesso';
        }

        Alert.success(message);
        queryClient.invalidateQueries(['optimization-prices-grid'], { exact: false }).then(() => {
            history.push(`/ipa/otimizacao-de-precos/lista`);
        });
    };

    useEffect(() => {
        const storesId =
            getParam('storesId')
                ?.split(',')
                .filter((item) => item) || [];

        const categoriesId =
            getParam('categoriesId')
                ?.split(',')
                .filter((item) => item) || [];

        if (storesId.length) {
            setValue('filters.storesId', storesId);
        }

        if (categoriesId.length) {
            setValue('filters.categories', categoriesId);
        }

        return () => {
            dispatch(resetOptimizationPrices());
        };
    }, []);

    const { isLoading } = useQuery({
        queryKey: ['get-optimization-by-id', params?.id],
        enabled: !!params?.id,
        queryFn: () => getOptimizationById(params?.id ?? ''),
        onSuccess(data) {
            const storesId = data.stores.map((store: any) => store.store_id);
            dispatch(
                setSelectedFilters({
                    type: 'categoriesId',
                    value: data.categories,
                }),
            );

            dispatch(
                setSelectedFilters({
                    type: 'storesId',
                    value: storesId,
                }),
            );

            dispatch(setPeriod({ period: data.recalculate.period }));
            dispatch(setOptions({ options: data.recalculate.days_of_week }));

            const { min_competitiveness, max_competitiveness, min_margin, max_margin } = data.restrictions;

            const minMargin = min_margin ? Number(min_margin) : null;
            const maxMargin = max_margin ? Number(max_margin) : null;
            const minCompetitiveness = min_competitiveness ? Number(min_competitiveness) : null;
            const maxCompetitiveness = max_competitiveness ? Number(max_competitiveness) : null;
            const acvtiveMargin = Boolean(minMargin && maxMargin);
            const acvtiveCompetitiveness = Boolean(minCompetitiveness && maxCompetitiveness);

            reset({
                filters: {
                    categories: data.categories,
                    storesId: storesId,
                },
                coordinates: {
                    competitiveness: {
                        active: acvtiveCompetitiveness,
                        min: minCompetitiveness,
                        max: maxCompetitiveness,
                    },
                    margin: {
                        active: acvtiveMargin,
                        min: minMargin,
                        max: maxMargin,
                    },
                    minVariationToChangePrice: Number(data.restrictions.variation),
                    objective: data.objective,
                },
                scheduleOptimizationPrices: {
                    period: {
                        value: data.recalculate.period,
                        options: data.recalculate.days_of_week,
                    },
                    validity: {
                        start: {
                            active: true,
                            startDate: new Date(data.validity.start_date),
                        },
                        end: {
                            active: data.validity.end_date ? true : false,
                            endDate: data.validity.end_date ? new Date(data.validity.end_date) : undefined,
                        },
                    },
                },
            });
        },
    });

    return {
        handlers: {
            handleSubmit,
            onSubmit,
            setValue,
            watch,
            clearErrors,
        },
        states: {
            control,
            errors,
            isLoading,
        },
    };
}
