import { create } from 'zustand';
import { CoverageSetupResponseData, CoverageUploadResponseType, UploadState } from './@types';

type State = {
    uploadState: UploadState,
    filtrosAnaliseCobertura: any,
    productUploadResponse: CoverageUploadResponseType,
    openModalProductUpload: boolean,
    openModalLojaUpload: boolean,
    productUploadError: boolean,
    isProductNewFile: boolean,
    isLojaNewFile: boolean,
    coverageSetupData: CoverageSetupResponseData,
    draftCoverageSetupData: CoverageSetupResponseData,
    produtoTableData: any,
    lojaTableData: any,
    lojaUploadResponse: CoverageUploadResponseType,
    lojaUploadError: boolean,
    openModalCoverageAlert: boolean,
}

type Actions = {
    setSetupInfoAnaliseDeCoberturaState: (name: string, data: any) => void,
    setCoverageData: (data: CoverageSetupResponseData) => void,
    setCoberturaProdutoTableData: () => void,
    setCoberturaLojaTableData: () => void,
    reset: () => void;
}

const initialState: State = {
    uploadState: UploadState.IDLE,
    filtrosAnaliseCobertura: {
        loja: {
            list: [],
            value: [],
            cache: [],
        },
        tipoLoja: {
            list: [],
            value: [],
            cache: [],
        },
        rede: {
            list: [],
            value: [],
            cache: [],
        },
        cidade: {
            list: [],
            value: [],
            cache: [],
        },
        uf: {
            list: [],
            value: [],
            cache: [],
        },
    },
    productUploadResponse: {} as CoverageUploadResponseType,
    openModalProductUpload: false,
    openModalLojaUpload: false,
    productUploadError: false,
    isProductNewFile: false,
    isLojaNewFile: false,
    coverageSetupData: {} as CoverageSetupResponseData,
    draftCoverageSetupData: {} as CoverageSetupResponseData,
    produtoTableData: {},
    lojaTableData: {},
    lojaUploadResponse: {} as CoverageUploadResponseType,
    lojaUploadError: false,
    openModalCoverageAlert: false,
};

export const useSetupInfoAnaliseDeCoberturaStore = create<State & Actions>((set) => ({
    ...initialState,
    setSetupInfoAnaliseDeCoberturaState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    setCoverageData: (data: CoverageSetupResponseData) => {
        const produtoData = {
            dataEnvio: data.dataEnvioGtins,
            usuario: data.usuarioEnvioGtins,
            nomeArquivo: data.nomeArquivoGtins,
        }

        const lojaData = {
            dataEnvio: data.dataEnvioLojas,
            usuario: data.usuarioEnvioLojas,
            nomeArquivo: data.nomeArquivoLojas,
        }

        const setList = (list: string[]) => {
            const newList = list.map((item: string) => ({
                label: item,
                value: item,
            }));
            return newList;
        }

        set(() => ({
            coverageSetupData: data,
            draftCoverageSetupData: data,
            produtoTableData: produtoData,
            lojaTableData: lojaData,
            productUploadResponse: {
                uploadId: data ? data?.uploadId : '',
                invalidos: data ? data?.invalidos : 0,
                nomeArquivo: data ? data?.nomeArquivoGtins : '',
                usuario: data ? data?.usuarioEnvioGtins : '',
                dataEnvio: data ? data?.dataEnvioGtins : '',
            },
            lojaUploadResponse: {
                uploadId: data ? data?.uploadId : '',
                invalidos: data ? data?.invalidos : 0,
                nomeArquivo: data ? data?.nomeArquivoLojas : '',
                usuario: data ? data?.usuarioEnvioLojas : '',
                dataEnvio: data ? data?.dataEnvioLojas : '',
            },
            filtrosAnaliseCobertura: {
                loja: {
                    value: data?.loja?.length ? data?.loja : [],
                    list: data?.loja?.length ? setList(data?.loja) : [],
                    cache: data?.loja?.length ? setList(data?.loja) : [],
                },
                tipoLoja: {
                    value: data?.tipoLoja?.length ? data?.tipoLoja : [],
                    list: data?.tipoLoja?.length ? setList(data?.tipoLoja) : [],
                    cache: data?.tipoLoja?.length ? setList(data?.tipoLoja) : [],
                },
                rede: {
                    value: data?.rede?.length ? data?.rede : [],
                    list: data?.rede?.length ? setList(data?.rede) : [],
                    cache: data?.rede?.length ? setList(data?.rede) : [],
                },
                cidade: {
                    value: data?.cidade?.length ? data?.cidade : [],
                    list: data?.cidade?.length ? setList(data?.cidade) : [],
                    cache: data?.cidade?.length ? setList(data?.cidade) : [],
                },
                uf: {
                    value: data?.uf?.length ? data?.uf : [],
                    list: data?.uf?.length ? setList(data?.uf) : [],
                    cache: data?.uf?.length ? setList(data?.uf) : [],
                },
            }
        }));
    },
    setCoberturaProdutoTableData: () => {
        const uploadData = (obj) => ({
            ...obj.productUploadResponse,
            dataEnvio: obj.draftCoverageSetupData?.dataEnvioGtins,
            usuario: obj.draftCoverageSetupData?.usuarioEnvioGtins,
            nomeArquivo: obj.draftCoverageSetupData?.nomeArquivoGtins,
            uploadId: null,
        })
        set((state) => ({
            productUploadResponse: uploadData(state),
            isProductNewFile: false,
        }));
    },
    setCoberturaLojaTableData: () => {
        const uploadData = (obj) => ({
            ...obj.lojaUploadResponse,
            dataEnvio: obj.draftCoverageSetupData?.dataEnvioLojas,
            usuario: obj.draftCoverageSetupData?.usuarioEnvioLojas,
            nomeArquivo: obj.draftCoverageSetupData?.nomeArquivoLojas,
            uploadId: null,
        })
        set((state) => ({
            lojaUploadResponse: uploadData(state),
            isLojaNewFile: false,
        }));
    },
    reset: () => {
        set(initialState);
    },
}));
