import React from 'react';
import styles from '../SetupInfoPanelAnaliseDeCobertura.module.scss';

export const AnaliseDeCoberturaHeader = () => (
    <div className={styles['setup-isa__main-config']}>
        <h3>Análise de Cobertura</h3>
        <p className={styles['setup-isa__main-config__header__subtitle']}>
            Ajuste as preferências para visualizar o resultado da sua Análise de Cobertura.
        </p>
    </div>
);
