import React from 'react';
import { ButtonPrimary } from '../../../../../../components';
import { ActionButtonsProps } from '../@types';
import styles from "../styles/ActionButtons.module.scss";


export const ActionButtons: React.FC<ActionButtonsProps> = ({ onCancel, onSave, onAnalyze }) => {
    return (
        <div className={styles['action-buttons']}>
            <div className={styles['right-buttons']}>
                <ButtonPrimary
                    type="reset"
                    onClick={onAnalyze}
                    theme="ghost"
                >
                    IR PARA A ANÁLISE DE COBERTURA
                </ButtonPrimary>
                <ButtonPrimary type="reset" theme="ghost" onClick={onCancel}>
                    DESCARTAR ALTERAÇÕES
                </ButtonPrimary>
                <ButtonPrimary type="submit" onClick={onSave}>
                    SALVAR
                </ButtonPrimary>
            </div>
        </div>
    );
};
