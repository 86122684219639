/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Control, Controller, FieldValues, FormState } from 'react-hook-form';
import { Radio, RadioGroup } from 'rsuite';
import { CoverageType, StoreType } from '../@types';
import styles from '../styles/InterestPoints.module.scss';
import { ContentUpload } from './ContentUpload/ContentUpload';
import { useSetupInfoAnaliseDeCoberturaStore } from '../SetupInfoPanelAnaliseDeCobertura.store';
import { CheckPickerController } from './CheckPickerController';
import { ModalUploadCoverageSetup } from './ModalUploadCoverageSetup';
import * as service from '../services';
import { useUploadCoverageFiles } from '../hooks/useUploadCoverageFiles';

const LINK_STORE_MODEL_FILE =
    'https://docs.google.com/spreadsheets/d/1zJxXtZ3jnR_xz9IkQpnUB8yuk0mYIi7ke5iKAZX7r3o/gviz/tq?tqx=out:csv&gid=1068508469';

type InterestPointsProps = {
    control: Control,
    formState: FormState<FieldValues>,
};

export const InterestPoints = ({ control, formState }: InterestPointsProps) => {
    const {
        draftCoverageSetupData,
        lojaTableData,
        lojaUploadError,
        lojaUploadResponse,
        isLojaNewFile,
        openModalLojaUpload,
        setCoberturaLojaTableData,
        setSetupInfoAnaliseDeCoberturaState
    } = useSetupInfoAnaliseDeCoberturaStore();

    const {
        uploadProgress,
        validateUploadLojaFile,
    } = useUploadCoverageFiles();

    const changeLocalValues = (value: CoverageType) => {
        const newValue = {
            UF: { ...draftCoverageSetupData, localInteresse: value, cidade: [] },
            CIDADE: { ...draftCoverageSetupData, localInteresse: value, uf: [] },
            TODO_TERRITORIO: { ...draftCoverageSetupData, localInteresse: value, uf: [], cidade: [] },
        }
        setSetupInfoAnaliseDeCoberturaState('draftCoverageSetupData', newValue[value]);
    }

    const changeLojasValues = (value: StoreType) => {
        const newValue = {
            LOJA: { ...draftCoverageSetupData, lojaInteresse: value, rede: [], tipoLoja: [] },
            REDE: { ...draftCoverageSetupData, lojaInteresse: value, loja: [], tipoLoja: [] },
            TIPO_LOJA: { ...draftCoverageSetupData, lojaInteresse: value, rede: [] }, // considerar limpar o upload de lojas
            TODAS: { ...draftCoverageSetupData, lojaInteresse: value, rede: [], tipoLoja: [] }, // considerar limpar o upload de lojas
        }
        setSetupInfoAnaliseDeCoberturaState('draftCoverageSetupData', newValue[value]);
    }

    const handleRadioChange = (value: string, onChange) => {
        changeLocalValues(value);
        onChange(value);
    };

    const handleLojasRadioChange = (value: string, onChange) => {
        changeLojasValues(value);
        onChange(value);
    };

    const handleClickCloseModalUpload = () => {
        setCoberturaLojaTableData();
        setSetupInfoAnaliseDeCoberturaState('openModalLojaUpload', false);
    };

    const handleDownloadLojaUploadErrors = async () => {
        await service.downloadStoreUploadErrors(lojaUploadResponse?.uploadId)
        setSetupInfoAnaliseDeCoberturaState('openModalLojaUpload', false);
    };

    const handleDownloadUploadedLoja = async () => {
        if (isLojaNewFile) {
            await service.downloadDraftUploadedStore(lojaUploadResponse)
        } else {
            await service.downloadCurrentUploadedStore(lojaUploadResponse)
        }
    };

    const handlClickOpenModalUpload = () => {
        setSetupInfoAnaliseDeCoberturaState('openModalLojaUpload', true);
        setSetupInfoAnaliseDeCoberturaState('lojaUploadError', false);
    };

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Pontos de interesse</h3>
            <p className={styles.description}>
                Selecione uma das opções abaixo para avaliar o quanto nossa base de dados cobre os pontos que você deseja monitorar.
            </p>

            <div className={styles.section}>
                <h4 className={styles.subtitle}>
                    Onde analisar a cobertura
                </h4>
                <p className={styles.subtitleDescription}>
                    O local escolhido para a análise de cobertura determinará os territórios abrangidos pelo índice de cobertura
                </p>
                <Controller
                    name="localInteresse"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup
                            {...field}
                            className={styles['radio-group']}
                            onChange={value => handleRadioChange(value, field.onChange)}
                        >
                            <Radio value="TODO_TERRITORIO" className={styles['radio-item']}>Todo o território contratado</Radio>
                            <Radio value="UF" className={styles['radio-item']}>UF's específicas</Radio>
                            <Radio value="CIDADE" className={styles['radio-item']}>Cidades específicas</Radio>
                        </RadioGroup>
                    )}
                />
                {control._formValues.localInteresse === CoverageType.UF && (
                    <CheckPickerController
                        name="uf"
                        title="Selecione as UF’s para a análise"
                        placeholder="Selecione as UF's"
                        control={control}
                        formState={formState}
                        required={control._formValues.localInteresse === CoverageType.UF}
                    />
                )}

                {control._formValues.localInteresse === CoverageType.CIDADE && (
                    <CheckPickerController
                        name="cidade"
                        title="Selecione as cidades para a análise"
                        placeholder="Selecione as cidades"
                        control={control}
                        formState={formState}
                        required={control._formValues.localInteresse === CoverageType.CIDADE}
                    />
                )}
            </div>
            <div className={styles.section}>
                <h4 className={styles.subtitle}>
                    Quais lojas considerar na análise
                </h4>
                <p className={styles.subtitleDescription}>
                    Escolha em quais lojas serão abrangidas pela sua análise de cobertura
                </p>
                <Controller
                    name="lojaInteresse"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup
                            {...field}
                            className={styles['radio-group']}
                            onChange={value => handleLojasRadioChange(value, field.onChange)}
                        >
                            <Radio value="TODAS" className={styles['radio-item']}>Todas as lojas e redes</Radio>
                            <Radio value="TIPO_LOJA" className={styles['radio-item']}>Lojas de um tipo de loja</Radio>
                            <Radio value="REDE" className={styles['radio-item']}>Redes específicas</Radio>
                            <Radio value="LOJA" className={styles['radio-item']}>Lojas específicas</Radio>
                        </RadioGroup>
                    )}
                />
                {control._formValues.lojaInteresse === StoreType.TIPO_LOJA && (
                    <CheckPickerController
                        name="tipoLoja"
                        title="Selecione o tipo de loja"
                        placeholder="Selecione o tipo de loja"
                        control={control}
                        formState={formState}
                        required={control._formValues.lojaInteresse === StoreType.TIPO_LOJA}
                    />
                )}
                {control._formValues.lojaInteresse === StoreType.REDE && (
                    <CheckPickerController
                        name="rede"
                        title="Selecione as redes específicas"
                        placeholder="Selecione as redes"
                        control={control}
                        formState={formState}
                        required={control._formValues.lojaInteresse === StoreType.REDE}
                    />
                )}

                {control._formValues.lojaInteresse === StoreType.LOJA && (
                    <ContentUpload
                        error={lojaUploadError}
                        tableData={[lojaTableData]}
                        isNewFile={isLojaNewFile}
                        uploadBoxTitle="Enviar lojas via arquivo"
                        uploadBoxSubtitle="Suba a sua lista de produtos selecionados para a análise através de um arquivo .csv"
                        onClickDownloadFile={handleDownloadUploadedLoja}
                        onClickOpenModalUpload={handlClickOpenModalUpload}
                    />
                )}

            </div>
            <ModalUploadCoverageSetup
                type='LOJA'
                show={openModalLojaUpload}
                linkModelFile={LINK_STORE_MODEL_FILE}
                invalidItems={lojaUploadResponse?.invalidos}
                uploadProgress={uploadProgress}
                onFileSelect={validateUploadLojaFile}
                onHide={() => setSetupInfoAnaliseDeCoberturaState('openModalLojaUpload', false)}
                onClickCloseAndReset={handleClickCloseModalUpload}
                onClickDownloadErrors={handleDownloadLojaUploadErrors}
            />
        </div>
    );
};

