import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { SelectUpdatedHeader } from '../../../../components/SelectUpdatedHeader';

import { LinkButton } from '../../../../../../../components';
import styles from './HeaderManageComercialTable.module.scss';
import { TabelaComercial } from '../../types';

type SelectPickerType = {
    label: string,
    value: string,
}

type HeaderManageComercialTableTypes = {
    tableTypeData?: SelectPickerType[],
    comercialTableItem?: TabelaComercial,
    onClickEdit?: () => void,
    onChangeTableType?: (id: number | string) => void,
}

export const HeaderManageComercialTable = ({
    tableTypeData,
    comercialTableItem,
    onClickEdit,
    onChangeTableType
}: HeaderManageComercialTableTypes) => {
    const history = useHistory();

    return (
        <div className={styles['header-wrapper']}>
            <div>
                <SelectUpdatedHeader
                    isPending={comercialTableItem?.revisaoPendente}
                    date={comercialTableItem?.atualizadoEm}
                    value={comercialTableItem?.id}
                    data={tableTypeData}
                    onChange={onChangeTableType}
                />
                <div className={styles['subtitle-wrapper']}>
                    <p className={styles.subtitle}>As alterações são salvas automaticamente</p>
                    <LinkButton
                        fontSize="12px"
                        onClick={onClickEdit}
                    >
                        Editar regras da tabela
                    </LinkButton>
                </div>
            </div>
            <Button
                className={styles['btn-consult']}
                onClick={() => history.push('/ipa/consultar-tabela-comercial')}
            >
                Consultar a tabela atual
            </Button>
        </div>
    )
}
